import { Spinner } from 'common/loading';
import FieldError from 'components/Common/FieldError';
import { TABLES } from 'pages/Utility/constants';
import { supabase } from 'pages/Utility/supabase';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { toast } from 'react-toastify';
import { Button, Container } from 'reactstrap';


const CustomInput = ({ label, value, name, placeholder, onChange, steric, error }) => {
    return (
        <div className="mb-">
            <label
                htmlFor="titleinput"
                className="font-weight-bold mb-0 form-label fs-5"
            >
                {label} <span className="text-info">{steric}</span>
            </label>
            <input
                style={{
                    height: "48px",
                    borderRadius: "8px",
                    backgroundColor: "#F8F8F8"
                }}
                type="text"
                value={value}
                className="form-control"
                name={name}
                placeholder={placeholder}
                onChange={onChange}
            />
            <FieldError text={error} />

        </div>
    );
};


const DEFAULT_FIELDS = {
    question: "",
    answer: "",
    seo_title: "",
    seo_description: "",
    description: "",
}
const AddFaqPage = () => {

    document.title = "Add Faq | HangarDirect"

    const history = useHistory()
    const [loading, setLoading] = useState(false)
    const [fields, setFields] = useState(DEFAULT_FIELDS);
    const [pastData, setPastData] = useState(null);
    const id = history?.location?.search?.replace("?id=", "")
    const [errors, setErrors] = useState({
        question: "",
        answer: "",
        seo_title: "",
        seo_description: "",
        description: "",
    })
    useEffect(() => {
        if (id)
            fetchRow()
    }, [])

    const fetchRow = async () => {
        try {
            const { data, error } = await supabase
                .from(TABLES.FAQ)
                .select(`*`)
                .eq("id", id)
                .single()

            if (error) throw error

            setFields(data);
            setPastData(data);



        } catch (error) {
            console.log(error.message)

        }
    }


    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const { name, value } = e.target

        setFields({ ...fields, [name]: value })
        setErrors({ ...errors, [name]: "" })
    }

    const validation = () => {
        let err: any = {}

        if (!fields?.question) err.question = "Please enter question"
        if (!fields?.answer) err.answer = "Please enter answer"
        if (!fields?.seo_title) err.seo_title = "Please enter seo title"
        if (!fields?.seo_description) err.seo_description = "Please enter seo description"
        if (!fields?.description) err.description = "Please enter description"

        if (Object.keys(err).length) {
            setErrors(err)
            return false
        }
        return true
    }

    function didUserChanged() {
        let changed = false
        if (fields?.question !== pastData?.question)
            changed = true
        if (fields?.answer !== pastData?.answer)
            changed = true
        if (fields?.seo_title !== pastData?.seo_title)
            changed = true
        if (fields?.seo_description !== pastData?.seo_description)
            changed = true
        if (fields?.description !== pastData?.description)
            changed = true

        if (!changed) throw new Error("Nothing to update")

    }

    const handleSubmit = async e => {
        e.preventDefault()

        try {

            if (!validation()) return;
            else if (!!id)
                didUserChanged()

            setLoading(true)
            const { question,
                answer,
                seo_title,
                seo_description,
                description } = fields

            const body = {
                question,
                answer,
                seo_title,
                seo_description,
                description
            }


            const { error, } = await supabase.from(TABLES.FAQ).upsert({ ...body, id: id || undefined }).select()

            if (error) throw error


            setFields(DEFAULT_FIELDS)
            toast.success(id ? "Update successfully" : "Added successfully")
            setLoading(false)
            history.goBack()


        } catch (error) {
            toast.error(error?.message)
            console.log('error: ', error.message);
        }
    }

    return (

        <div className="page-content">
            <Container fluid>
                <div className="mx- pb-4 shadow-md">
                    <div className="d-flex justify-content-between ">
                        <h4 className="mx-9 mb-3 text-[#252F40]  text-lg font-semibold">
                            {id ? "Update Faq" : "Add New Faq"}
                        </h4>
                    </div>
                    <div style={{ background: "white" }} className="p-4 rounded ">

                        <div className="row ">
                            <div className="col-lg-6 mt-4">

                                <CustomInput label={"Question"} steric={"*"} name="question" error={errors.question}
                                    value={fields.question} onChange={handleChange} placeholder={"Enter question"} />

                            </div>
                            <div className="col-lg-6 mt-4">

                                <CustomInput label={"Answer"} steric={"*"} name="answer" error={errors.answer}
                                    value={fields.answer} onChange={handleChange} placeholder={"Enter answer"} />
                            </div>
                        </div>


                        <div className="row ">
                            <div className="col-lg-6 mt-4">
                                <CustomInput label={"SEO Title"} steric={"*"} name="seo_title" error={errors.seo_title}
                                    value={fields.seo_title} onChange={handleChange} placeholder={"Enter seo title"} />
                            </div>
                            <div className="col-lg-6 mt-4">
                                <CustomInput label={"SEO Description"} steric={"*"} name="seo_description" error={errors.seo_description}
                                    value={fields.seo_description} onChange={handleChange} placeholder={"Enter seo description"} />
                            </div>
                        </div>

                        <div className="row mt-4">
                            <div className="col-lg-12">

                                <div className="mb-">
                                    <label
                                        htmlFor="titleinput"
                                        className="font-weight-bold mb-0 form-label fs-5"
                                    >
                                        Description <span className="text-info">*</span>
                                    </label>
                                    <textarea
                                        style={{
                                            height: "150px",
                                            borderRadius: "8px",
                                            backgroundColor: "#F8F8F8"
                                        }}
                                        type="text"
                                        value={fields.description}
                                        className="form-control"
                                        name={"description"}
                                        placeholder={"Enter description"}
                                        onChange={handleChange}
                                    />
                                    <FieldError text={errors.description} />

                                </div>

                            </div>
                        </div>


                        <div className="d-flex justify-content-end my-2 gap-2 mt-5">
                            <Button
                                type="button"

                                className="bg-secondary btn-rounded-3 px-4 py-2"
                                onClick={history.goBack}

                            >
                                {"Cancel"}
                            </Button>
                            <Button
                                type="button"
                                color="info"
                                className="btn-md btn-rounded-3 px-4 py-2"
                                onClick={handleSubmit}

                            >
                                {loading ? <Spinner /> : !id ? "Add" : "Update"}
                            </Button>
                        </div>

                    </div>

                </div>
            </Container>
        </div>
    )
}

export default AddFaqPage