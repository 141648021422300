import { Spinner } from 'common/loading';
import FieldError from 'components/Common/FieldError';
import { TABLES } from 'pages/Utility/constants';
import { createStripeProductAndPrice, updateStripeProductPrice } from 'pages/Utility/stripe_functions';
import { supabase } from 'pages/Utility/supabase';
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { toast } from 'react-toastify';
import { Container, Button } from 'reactstrap'


const CROSS_SVG = ({ onClick }) => (
    <svg
        width="20px"
        height="20px"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className='cursor-pointer'
        onClick={onClick}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M16.9498 8.46447C17.3404 8.07394 17.3404 7.44078 16.9498 7.05025C16.5593 6.65973 15.9261 6.65973 15.5356 7.05025L12.0001 10.5858L8.46455 7.05025C8.07402 6.65973 7.44086 6.65973 7.05033 7.05025C6.65981 7.44078 6.65981 8.07394 7.05033 8.46447L10.5859 12L7.05033 15.5355C6.65981 15.9261 6.65981 16.5592 7.05033 16.9497C7.44086 17.3403 8.07402 17.3403 8.46455 16.9497L12.0001 13.4142L15.5356 16.9497C15.9261 17.3403 16.5593 17.3403 16.9498 16.9497C17.3404 16.5592 17.3404 15.9261 16.9498 15.5355L13.4143 12L16.9498 8.46447Z"
            fill="#000000"
        />
    </svg>
)
const CustomInput = ({ label, value, name, id, placeholder, onChange, steric, onKeyDown, btnClick, btnText }) => {
    return (
        <div className="mb-">
            <label
                htmlFor="titleinput"
                className="font-weight-bold mb-0 form-label fs-5"
            >
                {label} <span className="text-info">{steric}</span>
            </label>
            <div className='d-flex gap-2'>
                <input
                    onKeyDown={onKeyDown}
                    style={{
                        height: "48px",
                        borderRadius: "8px",
                        backgroundColor: "#F8F8F8"
                    }}
                    type="text"
                    value={value}
                    className="form-control"
                    name={name}
                    id={id}
                    placeholder={placeholder}
                    onChange={onChange}
                />
                {!!btnText && <Button className='px-4' onClick={btnClick}>{btnText}</Button>}
            </div>

        </div>
    );
};
const Select = ({ heading, name, value, onChange, error, steric, options }) => {
    return (
        <div className="d-flex flex-column">
            <label className='font-weight-bold mb-0 form-label fs-5' htmlFor="mySelect">{heading} <span className="text-info">{steric}</span></label>

            <select style={{
                border: "none", width: "100%", padding: "13px",
                borderRadius: "8px",
                border: "1px solid #D7D7D7",
                backgroundColor: "#F8F8F8"
            }} onChange={onChange} value={value} name={name} id="mySelect">

                <option value={"Select"}>Select</option>
                {options?.map((op, ind) => <option key={ind} value={op?.value}>{op?.label}</option>)}
            </select>
            <FieldError text={error} />
        </div>
    )
}



const DEFAULT_FIELDS = {
    tier: "",
    amount: "",
    promo_days: "",
    features: [],
    exposure: "",
    marketing_team: "",
    media_advertising: ""
}
const AddPromotionPage = () => {
    const history = useHistory()
    const [loading, setLoading] = useState(false)
    const [fields, setFields] = useState(DEFAULT_FIELDS);
    const [oldData, setOldData] = useState(null);
    const id = history?.location?.search?.replace("?id=", "")
    const [feature, setFeature] = useState("");
    const [errors, setErrors] = useState({
        tier: "",
        amount: "",
        wingspan_feet: "",
        length_feet: "",
        height_feet: "",
    });
    useEffect(() => {
        if (history.location.search) {
            fetchData()
        }
    }, []);
    const fetchData = async () => {
        try {
            let { data = [], error } = await supabase
                .from(TABLES.PROMO_PACKAGES)
                .select("*")
                .eq("id", id)
                .single()
            if (error) {
                throw error
            } else {
                setFields(data);
                setOldData(data);
            }
        } catch (error) {
            console.log(error)
        }
    }

    const handleChange = e => {
        const { name, value } = e.target
        if (name === "amount") {
            setFields({ ...fields, [name]: value.replace(/\D/g, "") })
        }
        else {
            setFields({ ...fields, [name]: value })
        }

        setErrors({ ...errors, [name]: "" })
    }

    const handleChangeFeatures = (e) => {
        const { name, value } = e.target;

        if (e.key === "Enter") {
            if (value == "") return
            const exists = fields.features?.find((ft) => ft == value);
            if (exists) return toast.warn("Already added", { toastId: "Already added" })
            setFields({ ...fields, features: [...fields.features, value] });
            setFeature("");
            setErrors({ ...errors, features: "" });
        }
    }



    const handleRemoveFeature = (ft) => {
        const features = fields.features?.filter((feature) => feature !== ft);
        setFields({ ...fields, features })
    }

    const validation = () => {
        let err = {}

        if (!fields?.tier) err.tier = "Please enter tier"
        if (!fields?.amount) err.amount = "Please enter amount"
        if (!fields?.promo_days) err.promo_days = "Please select promotion days"
        if (!fields?.features?.length) err.features = "Please enter at least 1 feature"
        if (!fields?.exposure) err.exposure = "Please enter increase exposure"
        if (!fields?.media_advertising) err.media_advertising = "Please select media advertising"
        if (!fields?.marketing_team) err.marketing_team = "Please select marketing team"

        if (Object.keys(err).length) {
            setErrors(err)
            return false
        }
        return true
    }

    const deepEqual = (obj1, obj2) => {
        if (obj1 === obj2) return true;

        if (typeof obj1 !== "object" || obj1 === null || typeof obj2 !== "object" || obj2 === null) {
            return false;
        }

        const keys1 = Object.keys(obj1);
        const keys2 = Object.keys(obj2);

        if (keys1.length !== keys2.length) {
            return false;
        }

        for (let key of keys1) {
            if (!keys2.includes(key)) {
                return false;
            }

            if (typeof obj1[key] === "object" && typeof obj2[key] === "object") {
                if (!deepEqual(obj1[key], obj2[key])) {
                    return false;
                }
            } else {
                if (obj1[key] !== obj2[key]) {
                    return false;
                }
            }
        }

        return true;
    };

    const handleSubmit = async e => {
        e.preventDefault()

        try {

            const isEdit = !!oldData?.id;

            if (isEdit) {
                if (!deepEqual(oldData, fields)) {
                    // data changed
                } else {
                    // not changed
                    toast.warn("No changes to save");
                    return history.push("/plane-list");
                }
            }

            if (!validation()) return
            setLoading(true)

            const body = { ...fields, id };

            let price_id = body?.price_id;
            let product_id = body?.product_id;

            if (isEdit && oldData.amount !== body.amount) {
                const resp = await updateStripeProductPrice({ amount: body.amount, productId: body?.product_id });
                if (resp.success) {
                    price_id = resp?.data?.id;
                } else {
                    throw resp.message;
                }
            } else {
                let advertising_service = "txcd_20060002";
                const resp = await createStripeProductAndPrice({ amount: body.amount, name: body.tier, tax_code: advertising_service });
                if (resp?.success) {
                    price_id = resp.data.price.id;
                    product_id = resp.data.product.id;
                } else {
                    throw resp.message;
                }
            }

            const { error } = await supabase.from(TABLES.PROMO_PACKAGES).upsert({ ...body, price_id, product_id, id: id || undefined });
            if (error) {
                throw error
            }
            setFields(DEFAULT_FIELDS)
            toast.success(id ? "Update successfully" : "Submitted successfully")
            history.goBack()


        } catch (error) {
            toast.error(error?.message)
        } finally {
            setLoading(false);
        }
    }

    const addFeature = () => {
        if (feature == "") return
        setFields({ ...fields, features: [...fields.features, feature] });
        setFeature("");
        setErrors({ ...errors, features: "" });
    }


    return (
        <React.Fragment>
            <div id="titleinput" />
            <div className="page-content">
                <Container fluid>
                    <div className="mx- pb-4 shadow-md">
                        <div className="d-flex justify-content-between ">
                            <h4 className="mx-9 mb-3 text-[#252F40]  text-lg font-semibold">
                                {id ? "Edit Promotion Package" : "Add New Promotion Package"}
                            </h4>
                        </div>
                        <div style={{ background: "white" }} className="p-4 rounded ">
                            <div className="row">
                                <div className="col-lg-6">
                                    <CustomInput label={"Tier"} placeholder={"Enter Tier"} name={"tier"} onChange={handleChange} steric={"*"} value={fields?.tier} />
                                    <div className=" text-primary  w-full text-right">
                                        <FieldError text={errors?.tier} />
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <CustomInput label={"Amount"} placeholder={"Enter Amount"} name={"amount"} onChange={handleChange} steric={"*"} value={fields?.amount} />
                                    <div className=" text-primary  w-full text-right">
                                        <FieldError text={errors?.amount} />
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-4">
                                <div className='col-lg-6'>
                                    <Select heading={"Promotion Period"} name="promo_days" value={fields.promo_days} options={[
                                        { label: "1 Week", value: 7 },
                                        { label: "2 Weeks", value: 14 },
                                        { label: "3 Weeks", value: 21 },
                                        { label: "1 Month", value: 30 },
                                    ]} onChange={handleChange} />
                                    <div className=" text-primary  w-full text-right">
                                        <FieldError text={errors?.promo_days} />
                                    </div>
                                </div>
                                <div className='col-lg-6'>
                                    <Select heading={"Media Advertising"} name="media_advertising" value={fields.media_advertising} options={[
                                        { label: "True", value: true },
                                        { label: "False", value: false },
                                    ]} onChange={handleChange} />
                                    <div className=" text-primary  w-full text-right">
                                        <FieldError text={errors?.media_advertising} />
                                    </div>
                                </div>

                            </div>

                            <div className="row mt-4">
                                <div className='col-lg-6'>
                                    <Select heading={"Marketing Team"} name="marketing_team" value={fields.marketing_team} options={[
                                        { label: "True", value: true },
                                        { label: "False", value: false },
                                    ]} onChange={handleChange} />
                                    <div className=" text-primary  w-full text-right">
                                        <FieldError text={errors?.marketing_team} />
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <CustomInput btnClick={addFeature} btnText={"Add"} label={"Features"} placeholder={"Enter Features and hit enter"} name={"features"} onChange={(e) => setFeature(e.target.value)} onKeyDown={handleChangeFeatures} steric={"*"} value={feature} />
                                    <div style={{
                                        flexWrap: "wrap"
                                    }} className='p-2 d-flex gap-2'>
                                        {
                                            fields?.features?.map((ft, i) => {
                                                return (
                                                    <p key={i} style={{ background: "#e9ecef", width: "fit-content", borderRadius: "50px", padding: "4px 10px" }} className='small'>{ft} <CROSS_SVG onClick={() => handleRemoveFeature(ft)} /></p>
                                                )
                                            })
                                        }
                                    </div>
                                    <div className=" text-primary  w-full text-right">
                                        <FieldError text={errors?.features} />
                                    </div>
                                </div>
                            </div>

                            <div className="row mt-4">
                                <div className="col-lg-6">
                                    <CustomInput label={"Increase Exposure"} placeholder={"Enter increase exposure"} name={"exposure"} onChange={handleChange} steric={"*"} value={fields?.exposure} />
                                    <div className=" text-primary  w-full text-right">
                                        <FieldError text={errors?.exposure} />
                                    </div>
                                </div>
                            </div>

                            <div className="d-flex justify-content-end my-2 gap-2 mt-5">
                                <Button
                                    type="button"

                                    className="bg-secondary btn-rounded-3 px-4 py-2"
                                    onClick={() => history.goBack()}

                                >
                                    {"Cancel"}
                                </Button>
                                <Button
                                    type="button"
                                    color="info"
                                    className="btn-md btn-rounded-3 px-4 py-2"
                                    onClick={handleSubmit}

                                >
                                    {loading ? <Spinner /> : !id ? "Add" : "Update"}
                                </Button>
                            </div>

                        </div>

                    </div>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default AddPromotionPage

