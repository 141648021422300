import React from "react"
import PropTypes from "prop-types"
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap"
const PromoDetailModal = ({ isOpen, toggle, data }) => {
  return (
    <Modal
      isOpen={isOpen}
      role="dialog"
      autoFocus={true}
      centered={true}
      className="exampleModal"
      tabIndex="-1"
      toggle={toggle}
    >
      <ModalHeader toggle={toggle}>Package Details</ModalHeader>
      <div className="modal-content">
        <ModalBody>

          <p className="mb-2">Tier: <span>{data?.tier}</span></p>
          <p className="mb-2">Amount: <span>${data?.amount}</span></p>
          <p className="mb-2">Days: <span>{data?.promo_days}</span></p>

          <p className="mb-2">
            Features: <span  >{data?.features.join(" | ")}</span>
          </p>


        </ModalBody>
        <ModalFooter>
          <Button type="button" color="secondary" onClick={toggle}>
            Close
          </Button>
        </ModalFooter>
      </div>
    </Modal>
  )
}

PromoDetailModal.propTypes = {
  toggle: PropTypes.func,
  isOpen: PropTypes.bool,
}

export default PromoDetailModal
