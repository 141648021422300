import aws from "aws-sdk"
import { Buffer } from 'buffer';
import { toast } from "react-toastify";
import { getImageUrl } from "pages/Utility/functions";

const region = process.env.REACT_APP_AWS_REGION
const bucketName = process.env.REACT_APP_AWS_BUCKET_NAME
const accessKeyId = process.env.REACT_APP_AWS_ACCESS_KEY_ID
const secretAccessKey = process.env.REACT_APP_AWS_SECRET_ACCESS_KEY

const s3 = new aws.S3({
    region,
    accessKeyId,
    secretAccessKey,
    signatureVersion: 'v4'
})
s3.config.logger = console

export async function getReadFileAccessS3(link) {


    const params = { Bucket: bucketName, Key: link.split("amazonaws.com/")[1], Expires: 60 }

    return s3.getSignedUrlPromise('getObject', params)
}

export default async function uploadFileHandler(file, bucket, name) {
    try {


        const file_name = `${bucket}/${name}`

        const params = { Bucket: bucketName, Key: file_name, Expires: 60 * 5 }

        const url = await s3.getSignedUrlPromise('putObject', params)

        const img_upload = await fetch(url, {
            method: "PUT",
            headers: { "Content-Type": "multipart/form-data" },
            body: file
        })
            .catch(e => { throw e })

        if (!img_upload.ok) {
            throw { error: `File upload failed with status ${img_upload.status}` };
        }

        return url.split('?')[0]


    } catch (error) {
        toast.error(error.message)
        throw error

    }
}

export async function supabaseFileUploadToS3(link, folder, name) {

    const base64 = await getBase64(link)
    const ext = link.slice(link.length - 3)

    name += "." + ext

    return await uploadFileHandler(base64, folder, name)
}

export async function getBase64(fileUrl) {


    try {
        const response = await fetch(getImageUrl(fileUrl)).catch(e => { throw e })
        const buffer = await response.arrayBuffer()

        return Buffer.from(buffer, "base64")


    } catch (error) {
        console.error('Error fetching the file:', error.message)
    }
}

export async function deleteS3File(link) {
    try {
        
        const params = { Bucket: bucketName, Key: link.split("amazonaws.com/")[1], }

        return s3.deleteObject(params, (err, data) => {
            if (err) {
                console.error('Error deleting object:', err.message);
            }  
        });


    } catch (error) {
        toast.error(error.message)
        throw error

    }
}