import { Col, Container, Row } from "reactstrap"
import DefaultLoading from "common/loading"
import { supabase } from "pages/Utility/supabase"
import moment from "moment"
import { STATUS, TABLES, } from "pages/Utility/constants"
import { useHistory } from "react-router-dom/cjs/react-router-dom.min"
import React from "react"
import { useState, useEffect } from "react"
import Alerts from "common/Alerts/Alert"
import { Link } from "react-router-dom"
import { refundPayment } from "pages/Utility/stripe_functions"
import Detail from "components/Invoice/Detail"
import ClientBillingDetail from "components/Invoice/ClientBillingDetail"
import RequestModelInvoice from "./requestModel"
import { getImageUrl } from "pages/Utility/functions"
import { toast } from "react-toastify"
import Swal from "sweetalert2"

const OrderDetail = props => {
  document.title = "Reviews details - hangarDirect"

  const [loading, setLoading] = useState(true)
  const [approval, setApproval] = useState(true)
  const [status, setStatus] = useState([])
  const [refundModel, setRefundModel] = useState(false)
  const [invoiceDetail, setInvoiceDetail] = useState(null)
  const [isUserPlane, setIsUserPlane] = useState(null)
  const [refundedData, setRefundedData] = useState([])
  const [cancelledData, setCancelledData] = useState([])
  const [subscriptionData, setSubscriptionData] = useState([])
  const [reasonTxt, setReasonTxt] = useState('');
  const DEFAULT_ALERT_VALUES = {
    heading: "",
    message: "",
    type: "",
  };
  const [alert, setAlert] = useState(DEFAULT_ALERT_VALUES)
  const {
    match: { params },
  } = props

  const DYNAMIC_CHECK = invoiceDetail?.hangarData?.is_shared_pricing_model && invoiceDetail?.hangarData?.is_pricing_sq_ft;

  //   //meta title
  document.title = "Invoice Details - HangarDirect"
  const history = useHistory()

  useEffect(() => {
    if (params && params.id) {
      getOrderData(params.id);
      getRefundedOrderData(params.id);
      getCancelledOrderData(params.id);
      getSubscriptionData(params.id);
    }
    fetchStatuses();
  }, [history]);

  useEffect(() => {
    if (invoiceDetail && DYNAMIC_CHECK) {
      fetchUserPlane();
    }
  }, [invoiceDetail, DYNAMIC_CHECK]);

  //Print the Invoice
  const printInvoice = () => {
    window.print()
  }
  const Heading = ({ text, customClass }) => {
    return (
      <nav aria-label="breadcrumb">
        <ol className={`breadcrumb   ${customClass}`}>
          <li
            style={{ fontWeight: "600", color: "#495057" }}
            className="breadcrumb-item  active"
            aria-current="page"
          >
            {text}
          </li>
        </ol>
      </nav>
    )
  }
  const emailSender = async (stpFee) => {

    const diff = moment().diff(moment(invoiceDetail?.created_at), "days")
    let refundAmount = 0

    if (diff <= 1) {
      refundAmount = (+invoiceDetail?.total_Amount - +stpFee)
    } else {
      refundAmount = (+invoiceDetail?.total_Amount - +invoiceDetail?.serviceFee - +stpFee)
    }

    let objToSend = {
      name: invoiceDetail?.user_id?.meta?.first_name + ' ' + invoiceDetail?.user_id?.meta?.last_name,
      address: invoiceDetail?.hangarData?.address,
      airport: `${invoiceDetail?.hangarData?.near_airport?.ICAO} - ${invoiceDetail?.hangarData?.near_airport?.name}`,
      reservationDates: `${moment(invoiceDetail?.checkIn).format('ll')} - ${moment(invoiceDetail?.checkOut).format('ll')}`,
      tailNumber: invoiceDetail?.tailNumber,
      reservationID: invoiceDetail?.id,
      hangarImage: getImageUrl(invoiceDetail?.hangarData?.images[0]),
      title: invoiceDetail?.hangarData?.title,
      days: invoiceDetail?.days,
      reservationTotal: invoiceDetail?.hangarData?.price_per_night * +invoiceDetail?.days,
      totalAmount: +invoiceDetail?.total_Amount,
      serviceFee: +invoiceDetail?.serviceFee,
      tax: +invoiceDetail?.tax,
      stripeFee: stpFee,
      grand_total: parseFloat(refundAmount),
      diff: diff > 1,
      credits: (+invoiceDetail?.credits || 0)
    }
    let url = (window.location.origin.includes('web-app-admin') || window.location.origin.includes('localhost')) ? 'https://hangardirect-github-io.vercel.app/' : 'https://www.hangardirect.com/'
    fetch(`${url}api/send-email-react`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        email: invoiceDetail?.email,
        html: "html",
        subject: `Request Cancelled: Reservation #${invoiceDetail?.id}`,
        data: JSON.stringify(objToSend)
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        history.goBack()
      })
      .catch((e) => {
        console.log('e: ', e);
      });

  };

  const getOrderData = async id => {
    setLoading(true)
    try {
      let { data = [], error } = await supabase
        .from(TABLES.TRANSACTIONS)
        .select("*,user_id(*),hangar_owner(*)")
        .eq("id", id)
        .single()

      if (error) {
        throw error
      } else if (data) setInvoiceDetail(data)
    } catch (error) {
      console.log("error: ", error.message)
    }
    setLoading(false)
  }

  const getRefundedOrderData = async (transactionId) => {
    try {
      setLoading(true);
      let { data, error } = await supabase
        .from(TABLES.REFUND)
        .select("*")
        .eq("transaction_id", transactionId)

      if (error) {
        throw error;
      }

      if (data) {
        setRefundedData(data[0]);
      }
    } catch (error) {
      toast.error(error.message);
      console.error("error: ", error.message);
    } finally {
      setLoading(false);
    }
  }

  const fetchUserPlane = async () => {
    try {
      if (!invoiceDetail?.user_id?.id || !DYNAMIC_CHECK || !invoiceDetail?.aircraft_name) return;

      const { data, error } = await supabase
        .from(TABLES.USER_PLANES)
        .select("inches_area,name,tail_number")
        .eq("user_id", invoiceDetail.user_id.id)
        .eq("name", invoiceDetail.aircraft_name);

      if (error) throw error;

      if (data?.length) {
        const matchedPlane = data[0];
        setIsUserPlane(matchedPlane);
      }
    } catch (error) {
      console.error("Error fetching user planes:", error.message);
    }
  };

  const getCancelledOrderData = async (transactionId) => {
    try {
      setLoading(true);
      let { data, error } = await supabase
        .from(TABLES.TRANSACTIONS_CANCEL)
        .select("*")
        .eq("transaction_id", transactionId)
      if (error) {
        throw error;
      }

      if (data) {
        setCancelledData(data[0]);
      }
    } catch (error) {
      toast.error(error.message);
      console.error("error: ", error.message);
    } finally {
      setLoading(false);
    }
  }
  const getSubscriptionData = async (transactionId) => {
    try {
      setLoading(true);
      let { data, error } = await supabase
        .from(TABLES.SUBSCRIPTIONS)
        .select("*")
        .eq("supa_transaction_id", transactionId)
      if (error) {
        throw error;
      }

      if (data) {
        setSubscriptionData(data);
      }
    } catch (error) {
      toast.error(error.message);
      console.error("error: ", error.message);
    } finally {
      setLoading(false);
    }
  }



  const fetchStatuses = async () => {
    setLoading(true)
    try {
      let { data, error } = await supabase.from(TABLES.STATUS).select("*")

      if (error) {
        throw error
      } else if (data) setStatus(data)
    } catch (error) {
      console.log("error: ", error.message)
    }
    setLoading(false)
  }

  const refundHandler = async (e) => {
    e.preventDefault();
    setLoading(true);
  
    // Show confirmation dialog
    const result = await Swal.fire({
      title: "Do you want to process the refund?",
      text: "This action cannot be undone.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, refund it!",
      cancelButtonText: "No, cancel",
    });
  
    if (!result.isConfirmed) {
      setLoading(false);
      Swal.fire("Refund canceled", "No changes were made.", "info");
      return; // Exit the function if user cancels
    }
  
    if (invoiceDetail?.orderStatus === String(STATUS.ORDER_REFUNDED)) {
      setLoading(false);
      return Swal.fire("Already Refunded", "This order has already been refunded.", "warning");
    }
  
    invoiceDetail.stripeFee = (invoiceDetail?.total_Amount / 100) * 2.9 + 0.30;
  
    const response = await refundPayment({ invoiceDetail, reasonTxt });
  
    if (response?.success) {
      getOrderData(invoiceDetail?.id);
      setRefundModel(false);
      emailSender(invoiceDetail?.stripeFee);
      Swal.fire("Refund Successful", "The payment has been refunded.", "success");
    } else {
      setLoading(false);
      Swal.fire("Refund Failed", "There was an error processing the refund.", "error");
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {loading ? (
            <DefaultLoading />
          ) : (
            <>
              <RequestModelInvoice isOpen={refundModel} handleRefund={refundHandler} toggle={() => setRefundModel(!refundModel)} setReasonTxt={setReasonTxt} reasonTxt={reasonTxt} />
              {!approval && (
                <Alerts
                  type={alert.type}
                  heading={alert.heading}
                  message={alert.message}
                  clear={() => setAlert(DEFAULT_ALERT_VALUES)}
                />
              )}
              <div className="d-flex justify-content-between ">
                <Heading
                  text={`Order# ${invoiceDetail?.id}`}
                  customClass="px-0"
                />
                <div className="">
                  <Link to="#">
                    <button
                      onClick={printInvoice}
                      style={{ backgroundColor: "#556EE6", color: "white" }}
                      className="btn"
                    >
                      Print preview
                    </button>
                  </Link>

                  <button
                    onClick={() => history.goBack()}
                    style={{
                      backgroundColor: "#74788D",
                      color: "white",
                      marginLeft: "8px",
                    }}
                    className="btn"
                  >
                    Go back
                  </button>
                </div>
              </div>

              <Row>
                <Col xl="9">
                  <div className="bg-white px-2 rounded">
                    <div className="container pt-3">
                      <Heading text="Order Info" customClass="m-0 px-0 pt-0" />
                      <div style={{display:'flex',gap:'10px',flexWrap:'wrap'}}>
                      <img
                        style={{
                          width: "250px",
                          height:'180px',
                          borderRadius: "4px",
                          marginRight: "2px",
                          border:'1px solid #d7d7d7'
                        }}
                        src={getImageUrl(invoiceDetail?.hangarData?.images[0])}
                        alt="hangar detail img"
                      />
                      <img
                        style={{
                          width: "250px",
                          height:'180px',
                          borderRadius: "4px",
                          marginRight: "2px",
                          border:'1px solid #d7d7d7'
                        }}
                        src={getImageUrl(invoiceDetail?.hangarData?.images[1])}
                        alt="hangar detail img"
                      />
                      <img
                        style={{
                          width: "250px",
                          height:'180px',
                          borderRadius: "4px",
                          marginRight: "2px",
                          border:'1px solid #d7d7d7'
                        }}
                        src={getImageUrl(invoiceDetail?.hangarData?.images[3])}
                        alt="hangar detail img"
                      />
                      </div>

                      <div className="d-flex flex-column justify-content-between mr-3 py-2">
                        <table
                          style={{ backgroundColor: "white !important" }}
                          className="table-white table "
                        >
                          <thead className="">
                            <tr className="">
                              <th
                                scope="col"
                                style={{ color: "#737373", fontWeight: "500" }}
                              >
                                #
                              </th>
                              <th
                                scope="col"
                                style={{
                                  color: "#737373",
                                  fontWeight: "500",
                                }}
                              >
                                Hangar name
                              </th>
                              <th
                                scope="col"
                                style={{ color: "#737373", fontWeight: "500" }}
                              >
                                Price
                              </th>
                              <th
                                scope="col"
                                style={{ color: "#737373", fontWeight: "500" }}
                              >
                                Term
                              </th>
                              {invoiceDetail?.rentalType === "daily" &&
                                <th
                                  scope="col"
                                  style={{
                                    color: "#737373",
                                    fontWeight: "500",
                                    textOverflow: "ellipsis",
                                    whiteSpace: "nowrap",
                                  }}
                                >
                                  Reserved for
                                </th>
                              }
                              <th
                                scope="col"
                                style={{ color: "#737373", fontWeight: "500" }}
                              >
                                Total
                              </th>
                              <th
                                scope="col"
                                style={{
                                  color: "#737373",
                                  fontWeight: "500",
                                }}
                              >
                                Check-in
                              </th>
                              {invoiceDetail?.rentalType === 'daily' && <th
                                style={{
                                  color: "#737373",
                                  fontWeight: "500",
                                }}
                              >
                                Check-out
                              </th>}
                            </tr>
                          </thead>
                          <tbody>
                            <tr className="">
                              <td>{invoiceDetail?.hangarData?.id}</td>
                              <td
                                style={{
                                  color: "black",
                                  fontWeight: "500",
                                }}
                              >
                                {invoiceDetail?.hangarData?.title}
                              </td>
                              <td style={{ color: "black", fontWeight: "500" }}>
                                ${invoiceDetail?.rentalType === 'monthly' ? invoiceDetail?.hangarData?.price_per_month : invoiceDetail?.hangarData?.price_per_night}
                              </td>
                              <td style={{ color: "black", fontWeight: "500" }}>
                                {invoiceDetail?.rentalType === "daily" ? "Nightly" : "Monthly"}
                              </td>
                              {invoiceDetail?.rentalType === "daily" &&

                                <td style={{ color: "black", fontWeight: "500" }}>
                                  x{invoiceDetail?.days} days
                                </td>
                              }
                              <td style={{ color: "black", fontWeight: "500" }}>
                                ${invoiceDetail?.total_Amount}
                              </td>
                              <td style={{ color: "black", fontWeight: "500" }}>
                                {moment(invoiceDetail?.checkIn).format(
                                  "DD MMM YYYY"
                                )}
                              </td>
                              {invoiceDetail?.rentalType === 'daily' && <td style={{ color: "black", fontWeight: "500" }}>
                                {moment(invoiceDetail?.checkOut).format(
                                  "DD MMM YYYY"
                                )}
                              </td>}
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <Detail invoiceDetail={invoiceDetail} status={status} isUserPlane={isUserPlane} DYNAMIC_CHECK={DYNAMIC_CHECK} refundedData={refundedData} subscriptionData={subscriptionData} />
                </Col>
                {/* ------------------------------------------------------ */}
                <Col xl="3" className="p-0">
                  <ClientBillingDetail
                    setRefundModel={setRefundModel}
                    refundedData={refundedData}
                    refundHandler={refundHandler}
                    invoiceDetail={invoiceDetail}
                    cancelledData={cancelledData}
                  />
                </Col>
              </Row>
            </>
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}
export default OrderDetail