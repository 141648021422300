import React, { useState } from "react"
import { Link } from "react-router-dom"
import * as moment from "moment"
import { Badge } from "reactstrap"
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"
import { STATUS, TABLES } from "pages/Utility/constants"
import { supabase } from "pages/Utility/supabase"
const formateDate = (date, format) => {
  const dateFormat = format ? format : "DD MMM Y"
  const date1 = moment(new Date(date)).format(dateFormat)
  return date1
}

const CheckBox = cell => {
  return cell.value ? cell.value : ""
}

const OrderId = cell => {
  return (
    <Link to="#" className="text-body fw-bold">
      {cell?.row.original.id ? cell?.row.original.id : ""}
    </Link>
  )
}

const Title = cell => {
  return cell.row.original.title ? cell.row.original.title : ""
}
const Date = cell => {

  const date = moment(cell.row.original.created_at).format("LL")
  return date ? date : ""
}

const City = cell => {
  return cell.cell.row.original.city ? cell.cell.row.original.city : ""
}

const Status = cell => {

  return (
    <Badge
      style={{ textTransform: "capitalize", fontSize: "14px", padding: "5px 10px" }}
      className={
        "badge-soft-" +
        (cell.tabIndex === 1
          ? "warning"
          : cell.tabIndex === 2
            ? "success"
            : cell.tabIndex === 3
              ? "danger"
              : "info")
      }
    >
      {cell.tabIndex === 1
        ? "In Review"
        : cell.tabIndex === 2
          ? "Approved"
          : cell.tabIndex === 3
            ? "Rejected"
            : "Deleted!"}
    </Badge>
  )
}
const NearAirport = cell => {
  return (
    <span style={{ textTransform: "capitalize" }}>
      {cell.row?.original?.near_airport}
    </span>
  )
}

const Action = cell => {
  const [dropdownOpen, setDropdownOpen] = useState(false)

  const toggle = () => setDropdownOpen(prevState => !prevState)
  const id = cell.row?.original.id
  const user_id = cell.row?.original?.user_id
  const statusHandler = async value => {
    cell.setLoading(true)
    try {
      const { data: update, error } = await supabase
        .from(TABLES.HANGARS)
        .update({ status: value })
        .eq("id", id)
      if (error) {
        cell.setLoading(false)
        throw error
      }
      cell?.refresh && cell.refresh()
      cell.setLoading(false)
    } catch (error) { }
  }
  const check_coonect_account_approval = async () => {
    cell.setLoading(true)
    const { data, error } = await supabase
      .from(TABLES.USER_PROFILE)
      .select("connect_account_approval")
      .eq("id", user_id)
      .single()
    if (error) {
      cell.setLoading(false)
      console.log(error, "error_check_connect_account_approval")
    }
    if (data) {
      cell.setLoading(false)
      return data?.connect_account_approval
    } else {
      cell.setLoading(false)
      return false
    }
  }
  const handleStatus = async value => {
    const resultApproved = await check_coonect_account_approval()
    if (value === "Approve" && resultApproved) {
      statusHandler(STATUS.HANGAR_APPROVED)
    } else if (value === "Approve" && !resultApproved) {
      cell.setAlert({
        heading: "Restricted",
        message: "This user does not have connect account.",
        type: "danger",
      })
    }
    if (value === "Delete") {
      statusHandler(STATUS.HANGAR_DELETED)
    }
    if (value === "Reject") {
      statusHandler(STATUS.HANGAR_REJECTED)
    }
  }

  return (

    <div className="d-flex marginButton">
      <Dropdown
        // className="dropdownButton"
        className="dropdownButton"
        style={{ position: "absolute" }}
        isOpen={dropdownOpen}
        toggle={toggle}
        direction={"down"}
      >
        <DropdownToggle>Select</DropdownToggle>
        <DropdownMenu className="dropdownMenu">
          <DropdownItem onClick={e => handleStatus(e.target.innerText)}>
            Approve
          </DropdownItem>
          <DropdownItem onClick={e => handleStatus(e.target.innerText)}>
            Reject
          </DropdownItem>
          <DropdownItem onClick={e => handleStatus(e.target.innerText)}>
            Delete
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </div>
  )
}
export { CheckBox, OrderId, Title, Date, City, Status, NearAirport, Action }
