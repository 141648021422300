import Breadcrumb from "components/Common/Breadcrumb"
import React from "react"
import { Button, Col, Container, Row } from "reactstrap"
import { Id, Name, Date, Email, Description } from "./feedbackCol"
import { useEffect, useState, useMemo } from "react"
import { supabase } from "pages/Utility/supabase"
import { TABLES } from "pages/Utility/constants"
import DefaultLoading from "common/loading"
import Feedback from "./feedback"
import { toast } from "react-toastify"

const HangarFeedback = () => {
  const [loading, setLoading] = useState(false)
  const [hangars, setHangars] = useState([])
  const [modelPopData, setModelPopData] = useState(null);
  const [currentItems, setCurrentItems] = useState([]);


  document.title = "Feedbacks | HangarDirect"

  const handleModelData = data => {
    setModelPopData(data)
  }
  const columns = useMemo(
    () => [
      {
        Header: "Id",
        accessor: "Id",
        filterable: false,
        disableFilters: true,
        Cell: cellProps => {
          return <Id {...cellProps} />
        },
      },
      {
        Header: "Name",
        accessor: "Name",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <Name {...cellProps} />
        },
      },

      {
        Header: "Email",
        disableFilters: true,
        filterable: false,
        accessor: row => {
          const copyToClipboard = async (email) => {
            try {
              await navigator.clipboard.writeText(email);
              toast.success('Email copied to clipboard!');
            } catch (err) {
              toast.error('Failed to copy email!');
            }
          };

          return (
            <div
              className="d-flex align-items-center"
            >
              <div style={{ width: "85%" }} className=" text-truncate">{row?.email}</div>
              <i onClick={() => copyToClipboard(row?.email)} style={{ marginLeft: "10px" }} className="fa fa-regular xs fa-copy cursor-pointer" />
            </div>
          )
        },
      },
      {
        Header: "Description",
        accessor: "Description",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <Description {...cellProps} />
        },
      },

      {
        Header: "Image",
        disableFilters: true,
        filterable: false,
        accessor: row => {
          return (
            <div
              className=""

            >

              {row?.img_url ? <img style={{ height: "32px", width: "32px", marginLeft: "5px", borderRadius: "40px" }} src={row?.img_url} alt="" /> : ""}

            </div>
          )
        },
      },
      {
        Header: "Created date",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <Date {...cellProps} />
        },
      },

      {
        Header: "View details",
        disableFilters: true,
        accessor: "view",
        Cell: cellProps => {
          return (
            <Button
              type="button"
              color="primary"
              className="btn-sm btn-rounded"
              onClick={() => {
                handleModelData(cellProps.row.original)
              }}
            >
              View Details
            </Button>
          )
        },
      },
    ],
    []
  )

  const getHangarsData = async () => {
    setLoading(true)
    try {
      let { data, error } = await supabase
        .from(TABLES.FEEDBACK)
        .select("*")
        .order("id", { ascending: true })
      if (error) {
        throw error
      }

      setHangars(data);
      setCurrentItems(data);
    } catch (error) { }
    setLoading(false)
  }
  useEffect(() => {
    getHangarsData()
  }, [])
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* <Breadcrumb title="Hangar-Direct" breadcrumbItem="Requests" /> */}
          {loading ? (
            <DefaultLoading />
          ) : (
            <Row>
              <Col lg="12">
                <Feedback
                  columns={columns}
                  hangars={hangars}
                  modelPopData={modelPopData}
                  closeModal={() => setModelPopData(null)}
                  currentItems={currentItems}
                  setCurrentItems={setCurrentItems}
                />
              </Col>
            </Row>
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}

export default HangarFeedback
