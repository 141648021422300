import { Spinner } from "common/loading";
import FieldError from "components/Common/FieldError";
import { TABLES } from "pages/Utility/constants";
import { supabase } from "pages/Utility/supabase";
import React, { useState } from "react";
import { toast } from "react-toastify";
import { Button, Input, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

const CustomInput = ({ label, value, name, placeholder, onChange, error }) => {
    return (
        <div className="w-full">
            <label
                htmlFor="titleinput"
                className="font-weight-bold mb-0 form-label fs-5"
            >
                {label} <span className="text-info">{"*"}</span>
            </label>
            <input
                style={{
                    height: "48px",
                    borderRadius: "8px",
                    backgroundColor: "#F8F8F8"
                }}
                type="text"
                value={value}
                className={`form-control w-full ${error ? 'is-invalid' : ''}`}
                name={name}
                placeholder={placeholder}
                onChange={onChange}
            />
        </div>
    );
};

const AddWidgetModal = ({ visible, toggle, data }) => {
    const [fields, setFields] = useState({ name: "", slug: "", ...data })
    const [errors, setErrors] = useState({ name: "", slug: "" })
    const [loading, setLoading] = useState(false)
    const id = data?.id

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFields({ ...fields, [e.target.name]: e.target.value })
        // Clear error when user starts typing
        setErrors({ ...errors, [e.target.name]: "" })
    }

    const validateFields = () => {
        const newErrors = { name: "", slug: "" }
        let isValid = true

        if (!fields.name.trim()) {
            newErrors.name = "Name is required"
            isValid = false
        }

        if (!fields.slug.trim()) {
            newErrors.slug = "Slug is required"
            isValid = false
        }

        setErrors(newErrors)
        return isValid
    }

    const handleAdd = async () => {
        if (!validateFields()) {
            return
        }

        setLoading(true)
        try {
            const { name, slug } = fields
            const body = { name: name.trim(), slug: slug.trim(), id: id || undefined }

            const { data, error } = await supabase
                .from(TABLES.WIDGET)
                .upsert(body)

            if (error) {
                throw error
            } else {
                toast.success(`Widget successfully ${id ? 'updated' : 'added'}`)
                toggle(true)
            }
        } catch (error) {
            toast.error(error.message)
        }
        setLoading(false)
    }

    return (
        <Modal
            isOpen={visible}
            role="dialog"
            autoFocus={true}
            centered={true}
            toggle={toggle}
        >
            <ModalHeader toggle={toggle}>{id ? "Update" : "Add"} Widget</ModalHeader>

            <div className="modal-content">
                <ModalBody>
                    <div className="my-2">
                        <CustomInput 
                            label={"Name"} 
                            placeholder={"Enter Name"} 
                            name={"name"} 
                            onChange={handleChange} 
                            value={fields?.name}
                            error={errors.name}
                        />
                        <div className="text-primary w-full text-right">
                            <FieldError text={errors?.name} />
                        </div>
                    </div>

                    <div className="my-2">
                        <CustomInput 
                            label={"Slug"} 
                            placeholder={"Enter Slug"} 
                            name={"slug"} 
                            onChange={handleChange} 
                            value={fields?.slug}
                            error={errors.slug}
                        />
                        <div className="text-primary w-full text-right">
                            <FieldError text={errors?.slug} />
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button type="button" color="primary" onClick={handleAdd}>
                        {loading ? <Spinner hT={"20px"} wT={"20px"} /> : id ? "Update" : "Add"}
                    </Button>
                    <Button type="button" color="secondary" onClick={toggle}>
                        Close
                    </Button>
                </ModalFooter>
            </div>
        </Modal>
    )
}

export default AddWidgetModal