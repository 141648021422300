import React from "react"
import PropTypes from "prop-types"
import {
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap"
import { getImageUrl } from "pages/Utility/functions"
import { AVATAR } from "common/svg"

const RequestModel = ({
  isOpen,
  toggle,
  modelData,
}) => {
  const renderAvatar = () => {
    if (modelData?.meta?.img_url) {
      return (
        <img
          style={{ border: "1px", borderRadius: "100%" }}
          src={getImageUrl(modelData.meta.img_url)}
          alt=""
          className="avatar-md"
        />
      )
    }
    return <AVATAR width={32} height={32} custom="rounded-circle avatar-xs" />
  }

  return (
    <Modal
      isOpen={isOpen}
      role="dialog"
      autoFocus={true}
      centered={true}
      className="exampleModal"
      tabIndex="-1"
      toggle={toggle}
    >
      <ModalHeader toggle={toggle}>Invited by</ModalHeader>
      <div className="modal-content">
        <ModalBody>
          <div className="d-flex align-items-center gap-3 p-0.5">
            {renderAvatar()}
            <div>
              <h5 className="font-size-14 mb-0">
                {modelData?.meta?.first_name} {modelData?.meta?.last_name}
              </h5>
            </div>
          </div>
          <div className="d-flex align-content-center gap-2 pt-3 px-1">
            <h5>Email:</h5>
            <div>{modelData?.email}</div>
          </div>
        </ModalBody>
      </div>
    </Modal>
  )
}

RequestModel.propTypes = {
  toggle: PropTypes.func,
  isOpen: PropTypes.bool,
  modelData: PropTypes.shape({
    email: PropTypes.string,
    meta: PropTypes.shape({
      img_url: PropTypes.string,
      first_name: PropTypes.string,
      last_name: PropTypes.string,
    }),
  }),
}

export default RequestModel