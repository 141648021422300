import HangarMap from "components/Common/HangarMap"
import { supabase } from "pages/Utility/supabase"
import React from "react"
import { Col, Container, Row } from "reactstrap"
import { STATUS, TABLES } from "pages/Utility/constants"
import { useHistory } from "react-router-dom/cjs/react-router-dom.min"
import { useState } from "react"
import { useEffect } from "react"
import DefaultLoading from "common/loading"
import { supabaseFileUploadToS3 } from "helpers/file-uploader-s3"
import { getImageUrl } from "pages/Utility/functions"
import moment from "moment"
import { toast } from "react-toastify"
import Amenities from "components/hangar-details/Amenities"
import {
  hangarApproveEmail,
  matchingHangarAlert,
} from "components/hangar-details/emailTemplates"
import { USA_STATES } from "common/usa-states"
import Swal from "sweetalert2"
const stripe = require("stripe")(process.env.REACT_APP_STRIPE_SECRET_KEY)

const FORMAT_TIME_OPTION = {
  hour: "numeric",
  minute: "numeric",
}

const HangarRequestDetails = props => {
  document.title = "Hangar Details - HangarDirect"
  const history = useHistory()
  const [loading, setLoading] = useState(true)
  const [allAmenities, setAllAmenities] = useState([])
  const [allRules, setAllRules] = useState([])
  const [hangarData, setHangarData] = useState({})
  const [pastHangarData, setPastHangarData] = useState(null)
  const [tempData, setTempData] = useState(null)
  const [icao, setIcao] = useState(null)
  const {
    match: { params },
  } = props

  const temp_id = params?.id
  const isEdit = !!pastHangarData
  const DYNAMIC_CHECK =
    hangarData?.is_pricing_sq_ft || hangarData?.is_shared_pricing_model

  useEffect(() => {
    if (temp_id) {
      fetchData()
      fetchAmenity()
      fetchRules()
    }
  }, [history])

  const fetchData = async () => {
    setLoading(true)
    try {
      let { data: temp_data, error } = await supabase
        .from(TABLES.HANGARS_TEMP)
        .select("*, hangar_id(*), profile(*)")
        .eq("id", temp_id)
        .single()

      setPastHangarData(temp_data?.hangar_id)

      if (error) {
        throw error
      }
      if (temp_data?.near_airport) {
        const { data, } = await supabase.from(TABLES.AIRPORTS).select("ICAO").or(`ICAO.eq."${temp_data?.near_airport}", name.eq."${temp_data?.near_airport}"`).limit(1);

        if (!data?.length)
          toast.warn("This hangar includes airport which is not in our database. Please add with ICAO");

        else if (data[0]?.ICAO) {
          temp_data.near_airport = data[0]?.ICAO;
          setIcao(data[0]?.ICAO)
        } else {
          toast.warn("This hangar includes new airport with empty ICAO. Please add ICAO in airport table");
        }
      }
      const profile = temp_data.profile
      if (
        temp_data.status == STATUS.HANGAR_INREVIEW ||
        temp_data.status == STATUS.HANGAR_REJECTED
      ) {

        const data = { ...temp_data, ...temp_data.data }
        data.price_per_night = !data.price_per_night ? 0 : data.price_per_night
        data.price_per_month = !data.price_per_month ? 0 : data.price_per_month
        delete data?.id
        delete data?.hangar_id
        setHangarData(data)
      } else {

        if (temp_data?.data?.price_per_night !== undefined) {
          temp_data.data.price_per_night = !temp_data.data.price_per_night
            ? 0
            : temp_data.data.price_per_night
        }
        if (temp_data?.data?.price_per_month !== undefined) {
          temp_data.data.price_per_month = !temp_data.data.price_per_month
            ? 0
            : temp_data.data.price_per_month

        }
        if (temp_data.data?.near_airport) {
          temp_data.data = {
            ...temp_data.data,
            near_airport: temp_data.near_airport
          }
        }
        setTempData(temp_data.data)
        const getMeta = await fetchHangarMeta(
          temp_data?.data,
          temp_data.hangar_id.id
        )
        const temp = {
          ...temp_data.hangar_id,
          ...temp_data?.data,
          profile,
          ...getMeta,
          status: STATUS.HANGAR_UPDATE,
        }
        setHangarData(temp)
      }
    } catch (error) {
      toast.error(error.message)
      history.goBack()
    }
    setLoading(false)
  }

  const fetchAmenity = async () => {
    try {
      const { data, error } = await supabase.from(TABLES.AMENITIES).select("*")
      if (error) throw error
      if (data) setAllAmenities(data)
    } catch (error) {
      console.log("error: ", error)
    }
  }
  const fetchRules = async () => {
    try {
      const { data, error } = await supabase.from(TABLES.RULES).select("*")
      if (error) throw error
      if (data) setAllRules(data)
    } catch (error) {
      console.log("error: ", error)
    }
  }

  const fetchHangarMeta = async (data, hangar_id) => {
    const obj = {}

    if (!data?.hangar_amenity) {
      const { data } = await supabase
        .from(TABLES.HANGAR_AMENITIES)
        .select()
        .eq("hangar_id", hangar_id)
      // insert new amenities
      obj.hangar_amenity = data
    }

    if (!data?.hangar_rule) {
      // delete old hangar_rule
      const { data } = await supabase
        .from(TABLES.HANGAR_RULES)
        .select()
        .eq("hangar_id", hangar_id)

      obj.hangar_rule = data
    }

    if (!data?.property_rules) {
      const { data } = await supabase
        .from(TABLES.PROPERTY_RULES)
        .select()
        .eq("hangar_id", hangar_id)
      obj.property_rules = data
    }
    return obj
  }

  const Heading = ({ text, customClass, danger }) => {
    return (
      <nav aria-label="breadcrumb">
        <ol className={`breadcrumb ${customClass}`} style={{ marginBottom: '0px' }}>
          <li
            style={{ color: "#495057" }}
            className={`breadcrumb-item active fw-bold ${danger && "text-danger"
              }`}
            aria-current="page"
          >
            {text}
          </li>
        </ol>
      </nav>
    )
  }

  const formattedDate = new Date(
    hangarData?.check_in_time
  ).toLocaleString("en-US", FORMAT_TIME_OPTION)
  const CheckInDateEnd = new Date(hangarData?.check_out_time).toLocaleString(
    "en-US",
    FORMAT_TIME_OPTION
  )
  const quiet_hours_in_start = new Date(hangarData?.after_hours_start).toLocaleString("en-US", FORMAT_TIME_OPTION)
  const quiet_hours_in_end = new Date(hangarData?.after_hours_end).toLocaleString("en-US", FORMAT_TIME_OPTION)

  const openPreview = () => {
    window.open(
      `${window.location.origin.includes("localhost") ||
        window.location.origin.includes("web-app-admin")
        ? "https://hangardirect-github-io.vercel.app"
        : "https://www.hangardirect.com"
      }/${hangarData?.slug}/hangar/${hangarData.id}`,
      "blank"
    )
  }

  const createProductMonthlyStripe = async (hangarId, monthPrice) => {
    try {
      const subscriptionName = "Monthly subscription" + "(" + hangarId + ")"
      const product = await stripe.products.create({
        name: subscriptionName,
      })
      if (
        product
      ) {
        const { data: updated, error } = await supabase
          .from(TABLES.HANGARS)
          .update({
            stripe_monthly_product_id: product.id,
          })
          .eq("id", hangarId)
          .select()
      }
    } catch (error) {
      console.log("error: ", error)
    }
  }
  const getFoots = size => {
    let [foots, inches] = size
      .split(" ")
      .map(part => part.replace(/'|''/g, ""))
    foots = parseInt(foots, 10) || 0
    inches = parseInt(inches, 10) || 0
    let totalFoots = foots + inches / 12
    return +totalFoots.toFixed(2)
  }

  const approveHandler = async () => {
    if (loading) return

    const result = await Swal.fire({
      title: "Are you sure?",
      text: "Do you want to approve this hangar? This action cannot be undone.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, approve it!",
      cancelButtonText: "No, cancel",
    });

    if (!result.isConfirmed) {
      Swal.fire("Approval Canceled", "No changes were made.", "info");
      return; // Exit if the user cancels
    }

    const { data: foundAlertRequest, error: err } = (!hangarData?.longitude || !hangarData?.latitude) ? { data: [], error: "" } : await supabase.rpc('find_alert_hangars', {
      user_latitude: parseFloat(hangarData?.latitude),
      user_longitude: parseFloat(hangarData?.longitude),
    }).select(`
      *,
      user_id(*),
      plane(*),
      term(*)
    `);

    try {
      setLoading(true)
      const hangar_id = hangarData?.id
      let payload = hangar_id
        ? { ...tempData, id: hangar_id }
        : { ...hangarData, }
      let amenitiesToForward = payload?.hangar_amenity;
      let profileToForward = payload?.profile;
      delete payload?.hangar_amenity;
      delete payload?.property_rules;
      delete payload?.hangar_rule;
      delete payload?.profile;
      payload.status = STATUS.HANGAR_APPROVED;

      if (hangar_id && Object.keys(payload).length === 2) {
        const { data, error } = await supabase
          .from(TABLES.HANGARS_TEMP)
          .delete()
          .eq("id", temp_id)
        if (error) throw error
        handleUploadMeta(tempData, payload.id)
      }
      delete payload.data
      if (payload?.latitude)
        payload.gis_location = `POINT(${payload.longitude} ${payload.latitude})`
      if (payload?.state)
        payload.state_code = USA_STATES.find(i => i.name === hangarData.state).abbreviation


      payload.after_hours_start = payload.after_hours_start == "" ? null : payload.after_hours_start;
      payload.after_hours_end = payload.after_hours_end == "" ? null : payload.after_hours_end;
      payload.check_in_time = payload.check_in_time == "" ? null : payload.check_in_time;
      payload.check_out_time = payload.check_out_time == "" ? null : payload.check_out_time;

      const { data, error } = hangar_id
        ? await supabase
          .from(TABLES.HANGARS)
          .update(payload)
          .eq("id", payload.id)
          .select()
          .single()
        : await supabase.from(TABLES.HANGARS).insert(payload).select().single()

      if (error) throw error
      else if (data) {

        await supabase.from(TABLES.HANGARS_TEMP).delete().eq("id", temp_id)

        //create stripe product for monthly subscription
        if (
          !pastHangarData?.price_per_month > 0 ||
          !pastHangarData?.stripe_monthly_product_id
        )
          await createProductMonthlyStripe(data?.id, payload?.price_per_month)

        // images + handling to s3
        let body = {}
        const availableHangarId = data?.id
        const FOLDER_NAME = "hangars/" + data?.id
        if (!hangar_id) {
          body.images = await Promise.all(
            payload.images.map((link, i) =>
              supabaseFileUploadToS3(link, FOLDER_NAME, "image-" + (i + 1))
            )
          )
          emailSender(
            profileToForward?.email,
            data,
            profileToForward,
            body.images,
            amenitiesToForward
          )
          const listedForSelling = +hangarData?.price_per_sale > 0;

          const promises = foundAlertRequest?.map(item => {
            let isPlaneFitInHangar = true

            const planeData = item?.plane;

            if (!!planeData?.id) {
              const hangarWidthFoot = `${hangarData?.width_feet ?? 0}'`;
              const hangarWidthInches = `${hangarData?.width_inch ?? 0}''`;
              const hangarLengthFoot = `${hangarData?.length_feet ?? 0}'`;
              const hangarLengthInches = `${hangarData?.length_inch ?? 0}''`;
              let totalWidth = `${hangarWidthFoot} ${hangarWidthInches}`;
              let totalLength = `${hangarLengthFoot} ${hangarLengthInches}`;

              if ((getFoots(totalWidth) > getFoots(planeData?.wingspan)) && (getFoots(totalLength) > getFoots(planeData?.length))) {
                console.log("Matched")
              } else {
                isPlaneFitInHangar = false;
              }

            }
            if (
              (item?.term?.id === STATUS.LEASING_TERM_NIGHTLY
                ? +hangarData?.price_per_night > 0
                : item?.term?.id === STATUS.LEASING_TERM_MONTHLY
                  ? +hangarData?.price_per_month > 0
                  : item?.term?.id === STATUS.LEASING_TERM_SALE
                    ? listedForSelling
                    : +hangarData?.price_per_month > 0 ||
                    +hangarData?.price_per_night > 0 ||
                    listedForSelling)
            ) {
              if (isPlaneFitInHangar) {
                return alertEmailSender({ email: item?.user_id?.email, dataHangar: data, profileData: item?.user_id, images: body?.images, alertRowId: item?.id, availableHangarId, alertData: item });
              }
            }
          })
          await Promise.all(promises);
        } else if (payload?.images?.length) {
          const promises = payload.images.map((link, i) => {
            if (link.includes("https://")) return link
            else
              return supabaseFileUploadToS3(
                link,
                FOLDER_NAME,
                moment().unix() + "-image-" + i
              )
          })
          body.images = await Promise.all(promises)
        }

        // docs handling on s3

        if (Object.keys(body).length) {
          // deleteOldStorage()
          await supabase.from(TABLES.HANGARS).update(body).eq("id", data.id)
        }

        handleUploadMeta(hangar_id ? tempData : hangarData, data.id)
      } else {
        throw { message: "An error occurred, try again" }
      }
    } catch (error) {
      console.log("error: ", error.message)
      toast.error(error.message)

      setLoading(false)
    }
  }

  const handleUploadMeta = async (payload, hangar_id) => {
    try {
      if (payload?.hangar_amenity) {
        // old amenities deleting before new updating
        await supabase
          .from(TABLES.HANGAR_AMENITIES)
          .delete()
          .eq("hangar_id", hangar_id)
        // insert new amaenities
        const { data: amenty, error } = await supabase
          .from(TABLES.HANGAR_AMENITIES)
          .insert(
            payload?.hangar_amenity.map(i => ({
              amenity_id: i.id,
              hangar_id,
              price_per_day: i.price_per_day ? +i?.price_per_day : null,
              price_per_month: i.price_per_month ? +i?.price_per_month : null,
            }))
          )
      }

      if (payload?.hangar_rule) {
        // delete old hangar_rule
        await supabase
          .from(TABLES.HANGAR_RULES)
          .delete()
          .eq("hangar_id", hangar_id)

        // insert new hangar rules
        const { data: rulesAdded, error: rulesErr } = await supabase
          .from(TABLES.HANGAR_RULES)
          .insert(payload?.hangar_rule.map(i => ({ ...i, hangar_id })))
          .select()
      }

      if (payload?.property_rules) {
        await supabase
          .from(TABLES.PROPERTY_RULES)
          .delete()
          .eq("hangar_id", hangar_id)

        const { data: propertyUpdate, error: propertyError } = await supabase
          .from(TABLES.PROPERTY_RULES)
          .insert({ rules: payload?.property_rules[0]?.rules, hangar_id })
      }

      history.goBack()
    } catch (error) {
      console.log("error hangar meta: ", error.message)
      history.goBack()
    }
    setLoading(false)
  }

  const rejectHandler = async () => {
      if (loading) return
      const result = await Swal.fire({
      title: "Are you sure?",
      text: "Do you want to reject this hangar request? This action cannot be undone.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, reject it!",
      cancelButtonText: "No, cancel",
    });

    if (!result.isConfirmed) {
      Swal.fire("Rejection Canceled", "No changes were made.", "info");
      return; // Exit if the user cancels
    }
    try {
      setLoading(true)
      if (!pastHangarData) {
        const { error } = await supabase
          .from(TABLES.HANGARS_TEMP)
          .update({ status: STATUS.HANGAR_REJECTED })
          .eq("id", temp_id)
        if (error) throw error
      } else {
        const { error: err } = await supabase
          .from(TABLES.HANGARS_TEMP)
          .delete()
          .eq("id", temp_id)
        if (err) throw err
      }

    } catch (error) {
      toast.error(error.message)
      setLoading(false)
    } finally {
      history.goBack("/hangar-requests")
    }
  }

  const InstructionRow = ({ title, value, changed }) => (
    <div className="container pb-3 ">
      <div className=" border-bottom pb-2">
        <div className="row px-2">
          <div className={`col-lg-3 fw-bold ${changed && "text-danger"}`}>
            {title}:
          </div>
          <div className="col-lg-9">{value}</div>
        </div>
      </div>
    </div>
  )

  const LabelItem = ({ label, changed, customClass }) => (
    <div
      className={`col-lg-2  fw-bold ${changed && "text-danger"} ${customClass}`}
    >
      {label}:
    </div>
  )

  const alertEmailSender = async ({
    email,
    dataHangar,
    profileData,
    images,
    alertRowId,
    availableHangarId,
    alertData,
  }) => {
    try {
      let subject = "A Hangar Matching Your Preferences is Now Available"
      const { data: dataAlertEmail, error: errorAlertEmail } =
        await supabase.rpc("send_email", {
          email,
          subject,
          html: matchingHangarAlert(dataHangar, profileData, images, alertData),
        })

      if (dataAlertEmail) {
        const { data, error } = await supabase
          .from(TABLES.ALERT_HANGARS)
          .update({ available_hangar: availableHangarId })
          .eq("id", alertRowId)
          .select("*")
      }
      if (errorAlertEmail) throw errorAlertEmail;

    } catch (error) {
      console.log("error: ", error)
    }
  }

  const emailSender = async (
    email,
    dataHangar,
    profileData,
    images,
    amenities
  ) => {
    try {
      const { data, error } = await supabase.rpc("send_email", {
        email,
        subject: `Hangar Approved`,
        html: hangarApproveEmail(
          dataHangar,
          profileData,
          amenities.map(amen => ({
            ...amen,
            ...allAmenities.find(i => i?.id == amen.id),
          })),
          images
        ),
      })
      if (error) throw error
    } catch (error) {
      console.log("error: ", error)
    }
  }

  return (
    <div className="page-content" style={{ position: "relative" }}>
      {loading && <DefaultLoading style={{ position: "absolute", height: "100vh", width: "100%", }} />}
      <Container fluid>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '10px' }}>
          <Heading text="Request Details" customClass="px-0" />
          <button
            onClick={() => history.goBack()}
            style={{
              backgroundColor: "#74788D",
              color: "white",
              marginLeft: "8px",
            }}
            className="btn"
          >
            Go back
          </button>
        </div>
        <Row>
          <Col xl="8">
            <div className="bg-white px-2 rounded">
              <div className="container pt-3">
                <Heading text="Hangar Info" customClass="m-0 px-0 pt-0" />
                <div className="border-top border-bottom py-2">
                  <div className="row px-2">
                    <LabelItem
                      label={"Title"}
                      changed={
                        pastHangarData &&
                        hangarData?.title !== pastHangarData.title
                      }
                    />

                    <div className="col-lg-5">{hangarData?.title}</div>

                    <LabelItem
                      label={
                        hangarData?.price_per_sq_ft_night
                          ? "Price per sq_ft_night"
                          : "Per Night Price"
                      }
                      changed={tempData?.price_per_night !== undefined}
                    />

                    <div className="col-sm">
                      $
                      {hangarData?.price_per_night &&
                        (+hangarData.price_per_night).toFixed(2)}
                    </div>
                  </div>
                </div>
              </div>
              {/* 2nd row */}
              <div className="container py-3 ">
                <div className=" border-bottom pb-2">
                  <div className="row px-2">
                    <LabelItem
                      label={
                        hangarData?.price_per_sq_ft_month
                          ? "Price per sq_ft_month"
                          : "Per Month Price"
                      }
                      changed={tempData?.price_per_month !== undefined}
                    />

                    <div className="col-sm">
                      $
                      {hangarData?.price_per_month &&
                        (+hangarData.price_per_month).toFixed(2)}
                    </div>

                    <LabelItem
                      label={"Length"}
                      changed={tempData?.length_feet}
                    />

                    <div className="col-lg-3">
                      {`${hangarData?.length_feet}'${hangarData?.length_inch}''`}
                    </div>
                  </div>
                </div>
              </div>
              {/* 3rd row */}
              <div className="container pb-3 ">
                <div className=" border-bottom pb-2">
                  <div className="row px-2">
                    <LabelItem
                      label={"Width"}
                      changed={tempData?.width_feet}
                    />

                    <div className="col-sm">
                      {`${hangarData?.width_feet}'${hangarData?.width_inch}''`}
                    </div>

                    <LabelItem
                      label={"Door Height"}
                      changed={tempData?.door_height_feet}
                    />

                    <div className="col-lg-3">
                      {`${hangarData?.door_height_feet}'${hangarData?.door_height_inch}''`}
                    </div>
                  </div>
                </div>
              </div>
              <div className="container pb-3 ">
                <div className=" border-bottom pb-2">
                  <div className="row px-2">
                    <LabelItem
                      label={"Door Width"}
                      changed={tempData?.door_width_feet}
                    />

                    <div className="col-sm">
                      {hangarData?.door_width_feet +
                        "'" +
                        hangarData?.door_width_inch +
                        "''"}
                    </div>

                    <LabelItem
                      label={"Selling Price"}
                      changed={tempData?.data?.price_per_sale}
                    />

                    <div className="col-lg-3">
                      ${(+hangarData?.price_per_sale || 0).toFixed(2)}
                    </div>
                  </div>
                </div>
              </div>

              {/* 5th row */}
              <div className="container pb-3 ">
                <div className=" border-bottom pb-2">
                  <div className="row px-2">
                    <LabelItem
                      label={"Description"}
                      changed={
                        pastHangarData &&
                        hangarData?.description !== pastHangarData.description
                      }
                    />

                    <div
                      className="col-lg-5"
                      dangerouslySetInnerHTML={{
                        __html: hangarData.description,
                      }}
                    ></div>

                    <>
                      <div className="col-lg-2" style={{ fontWeight: "600" }}>
                        Pricing Model:
                      </div>
                      <div className="col-lg-1">
                        {DYNAMIC_CHECK ? "Shared" : "Entire"}
                      </div>
                    </>
                  </div>
                </div>
              </div>

              {hangarData?.instructions?.instructions && (
                <InstructionRow
                  title={"Special Instructions"}
                  changed={tempData?.instructions?.instructions}
                  value={hangarData?.instructions?.instructions}
                />
              )}

              {hangarData?.instructions?.self_check_in && (
                <InstructionRow
                  title={"Instructions (Self Check-in)"}
                  changed={tempData?.instructions?.self_check_in}
                  value={hangarData?.instructions?.self_check_in}
                />
              )}

              {hangarData?.instructions?.keypad && (
                <InstructionRow
                  title={"Instructions (Keypad)"}
                  changed={tempData?.instructions?.keypad}
                  value={hangarData?.instructions?.keypad}
                />
              )}

              {hangarData?.instructions?.lock_box && (
                <InstructionRow
                  title={"Instructions (Lock box)"}
                  changed={tempData?.instructions?.lock_box}
                  value={hangarData?.instructions?.lock_box}
                />
              )}
              {hangarData?.instructions?.staff_on_site && (
                <InstructionRow
                  title={"Instructions (Staff on-site)"}
                  changed={tempData?.instructions?.staff_on_site}
                  value={hangarData?.instructions?.staff_on_site}
                />
              )}

            </div>
            {hangarData?.hangar_amenity?.length > 0 && (
              <div className="bg-white px-2 my-4 pb-3 rounded">
                <Heading
                  text="Amenities"
                  danger={tempData?.hangar_amenity}
                  customClass={`m-0 px-1`}
                />

                <Amenities
                  amenities={hangarData?.hangar_amenity.map(amen => ({
                    ...amen,
                    ...allAmenities.find(
                      i => i?.id == (amen?.amenity_id || amen?.id)
                    ),
                  }))}
                />
              </div>
            )}
            {/* hangar rules */}
            <div className="bg-white px-2 my-4 pb-3 rounded">
              <Heading text="Hangar Rules" customClass="m-0 px-1" />
              <div className="container py-3 ">
                <div className=" border-bottom pb-2">
                  <div className="row px-2">
                    <div
                      className={`col-lg-4 mb-2 fw-bold ${tempData?.always_on !== undefined ? "text-danger" : ""
                        }`}
                    >
                      <input
                        type="checkbox"
                        style={{ marginRight: "5px" }}
                        checked={tempData?.always_on || hangarData?.always_on}
                      />
                      {"24/7 check-in-available"}
                    </div>
                  </div>
                  {
                    <div className="row px-2">
                      {hangarData?.check_in_time && (
                        <>
                          <LabelItem
                            label={"Check-in"}
                            changed={tempData?.check_in_time}
                          />
                          <div className="col-lg-5">{formattedDate}</div>
                        </>
                      )}
                      {hangarData?.check_out_time && (
                        <>
                          <LabelItem
                            label={"Check Out"}
                            changed={tempData?.check_out_time}
                          />

                          <div className="col-sm">{CheckInDateEnd}</div>
                        </>
                      )}
                    </div>
                  }
                </div>
              </div>
              {quiet_hours_in_start !== "Invalid Date" && (
                <div className="container py-3 ">
                  <Heading
                    text="Quiet Hours"
                    danger={
                      tempData?.after_hours_start || tempData?.after_hours_end
                    }
                    customClass="mb-2 px-1"
                  />
                  <div className=" border-bottom pb-2">
                    <div className="row px-2">
                      <LabelItem label={"From"} />

                      <div className="col-lg-5">
                        {quiet_hours_in_start === "Invalid Date"
                          ? "Not Found"
                          : quiet_hours_in_start}
                      </div>
                      <LabelItem label={"To"} />

                      <div className="col-sm">
                        {quiet_hours_in_end === "Invalid Date"
                          ? "Not Found"
                          : quiet_hours_in_end}
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {/* allowedRules */}
              {hangarData?.hangar_rule?.length > 0 && (
                <div className="container pt-3 ">
                  <Heading
                    text="Rules"
                    danger={tempData?.hangar_rule}
                    customClass="mb-2 px-1"
                  />
                  {/* <Heading text="Hangar Rules" customClass="mb-2 px-1" /> */}
                  <div className="text-black container inline-block">
                    <div className="w-auto flex-wrap flex flex-col gap-2 align-items-center">
                      {hangarData?.hangar_rule?.length ? (
                        hangarData?.hangar_rule?.map((item, index) => {
                          const rule = allRules?.find(
                            i => i?.id == item?.rule_id
                          )
                          return (
                            <div
                              className="d-flex align-items-center gap-2"
                              key={index}
                            >
                              <div
                                style={{
                                  height: "5px",
                                  width: "5px",
                                  backgroundColor: "#D7D7D7",
                                  borderRadius: "100%",
                                }}
                              />
                              <p
                                style={{
                                  color: "#495057",
                                  fontWeight: "500",
                                  fontSize: "14px",
                                }}
                                className="text-sm font-medium m-0  leading-"
                              >
                                {rule?.title +
                                  "" +
                                  (item.is_allow === true
                                    ? " allowed"
                                    : " not allowed")}
                              </p>
                            </div>
                          )
                        })
                      ) : (
                        <div className="text-warning">
                          No hangar rules found!
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}
              {hangarData?.property_rules?.[0]?.rules?.some(rule => rule?.trim()) && (
                <div className="container py-3 ">
                  <Heading
                    text="Property Rules"
                    danger={tempData?.property_rules}
                    customClass="mb-2 px-1"
                  />
                  <div className="text-black container inline-block">
                    <div className="w-auto flex-wrap d-flex flex-row gap-2 align-items-center">
                      {!!hangarData?.property_rules?.length &&
                        hangarData?.property_rules[0].rules?.length ? (
                        hangarData?.property_rules[0].rules?.map(
                          (item, index) => {
                            return (
                              <div
                                className="d-flex align-items-center gap-2"
                                key={index}
                              >
                                <div
                                  style={{
                                    height: "5px",
                                    width: "5px",
                                    backgroundColor: "#D7D7D7",
                                    borderRadius: "100%",
                                  }}
                                />
                                <p
                                  style={{
                                    color: "#495057",
                                    fontWeight: "500",
                                    fontSize: "14px",
                                  }}
                                  className="text-sm font-medium m-0  leading-"
                                >
                                  {item}
                                </p>
                              </div>
                            )
                          }
                        )
                      ) : (
                        <div className="text-warning">
                          No property rules found!
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>

            {/* hangar images */}
            <div className="bg-white px-2 mt-4 rounded pb-2">
              <div className="container">
                <Heading
                  text="Hangar Images"
                  danger={tempData?.images}
                  customClass="m-0 px-0"
                />
                <div className="d-flex flex-wrap gap-5">
                  {hangarData?.images?.map((item, index) => (
                    <div className="d-flex pt-4" key={item}>
                      <img
                        height={"170px"}
                        className="rounded"
                        width={"170px"}
                        src={getImageUrl(item)}
                      />
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </Col>
          <Col xl="4" className="p-0">
            {hangarData?.id && (
              <div className="container px-2">
                <div className="row mx-0 pb-3">
                  <button
                    onClick={openPreview}
                    type="button"
                    className="btn btn-primary"
                  >
                    Preview
                  </button>
                </div>
              </div>
            )}

            {(hangarData?.status == 1 || hangarData?.status == 21) && (
              <div className="container px-2">
                <div className="row mx-0">
                  <button
                    onClick={approveHandler}
                    type="button"
                    className="btn btn-primary"
                    disabled={!icao}
                  >
                    {hangarData?.id ? 'Update Hangar' : 'Approve'}
                  </button>
                </div>
                <div className="row pt-3 mx-0">
                  <button
                    onClick={rejectHandler}
                    // disabled={hangarData?.status !== STATUS.HANGAR_INREVIEW}
                    type="button"
                    className="btn btn-secondary"
                  >
                    Reject
                  </button>
                </div>
                {/* <div className="row pt-3 mx-0">
                    <button
                      onClick={() => statusHandler(STATUS.HANGAR_DELETED)}
                      // disabled={hangarData?.status !== "1"}
                      type="button"
                      className="btn btn-warning"
                    >
                      DELETE
                    </button>
                  </div> */}
              </div>
            )}

            <div
              className={`${hangarData?.status == 1 ? "mx-2" : "mx-2"} ${hangarData?.status == 3 ? "mt-0" : "mt-3"
                }  pb-2 bg-white rounded`}
            >
              <Heading text="Hangar Owner Detail" customClass="m-0 " />

              <div className="px-3">
                <div className="d-flex align-items-center gap-2">
                  <img
                    src="/images/user.svg"
                    alt="user-icon"
                    style={{ width: "20px" }}
                  />
                  <span style={{ fontSize: "13px" }}>
                    {`${hangarData?.profile?.meta?.first_name} ${hangarData?.profile?.meta?.last_name}`}
                  </span>
                </div>
                <div className=" d-flex align-items-center gap-2 mt-1">
                  <img
                    src="/images/email.svg"
                    alt="user-icon"
                    style={{ width: "20px" }}
                  />
                  <span style={{ fontSize: "13px" }}>
                    {hangarData?.profile?.email}
                  </span>
                </div>
              </div>
            </div>
            <div className="m-2 mt-3 pb-2 bg-white rounded">
              <Heading text="Hangar Location" customClass="m-0 " />
              <div className="px-3">
                <div className="d-flex align-items-start gap-2">
                  <img
                    src="/images/plane.svg"
                    alt="gps-icon"
                    style={{ width: "20px" }}
                  />
                  <span
                    style={{ fontSize: "13px" }}
                    className={tempData?.near_airport && "text-danger"}
                  >
                    {tempData?.near_airport || hangarData?.near_airport}
                  </span>
                </div>
                <div className="d-flex align-items-start gap-2 my-2">
                  <img
                    src="/images/pin.svg"
                    alt="gps-icon"
                    style={{ width: "20px" }}
                  />
                  <span
                    style={{ fontSize: "13px" }}
                    className={tempData?.address && "text-danger"}
                  >
                    {hangarData?.address}
                  </span>
                </div>
                {window?.google && (
                  <HangarMap
                    latitude={hangarData?.latitude}
                    longitude={hangarData?.longitude}
                    customPadding={"px-0"}
                  />
                )}
              </div>
            </div>
          </Col>
        </Row>

      </Container>
    </div>
  )
}

export default HangarRequestDetails
