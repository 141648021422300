import React, { useEffect, useState } from "react"
import { withRouter } from "react-router-dom"
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
} from "reactstrap"
import TableContainer from "../../components/Common/TableContainer"
import DefaultLoading from "common/loading"
import { supabase } from "pages/Utility/supabase"
import moment from "moment"
import { getImageUrl } from "pages/Utility/functions"
import { DATE_FORMATS, TABLES } from "pages/Utility/constants"
import { copyToClipboard } from "pages/Utility/helper"


const CopyField = ({ text, style }) => {
  return (
    <div className="max-w-[300px]" style={style} >
      {text}
      <span style={{ marginLeft: '5px', cursor: "pointer" }}  >
        <span onClick={() => copyToClipboard(text)} >
          <i className="fa fa-regular xs fa-copy" />
        </span>
      </span>
    </div>
  )
}

const UnverifiedUsers = props => {


  document.title = "Unverified Users | HangarDirect"
  const [usersData, setUsersData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentItems, setCurrentItems] = useState([]);
  useEffect(() => {
    getUserData()
  }, [])

  const getUserData = async () => {
    setLoading(true)
    try {
      let { data = [], error } = await supabase
      .from(TABLES.UNVERIFIED_USERS)
        .select("id, img_url:raw_user_meta_data->>img_url, first_name:raw_user_meta_data->>first_name, last_name:raw_user_meta_data->>last_name,phone,email,created_at")
        .order("created_at", { ascending: false })
      if (error) {
        throw error
      }
      let newData = data.map(i => ({ ...i, full_name: i.first_name + " " + i.last_name }))
      setUsersData(newData);
      setCurrentItems(newData);
    } catch (error) {
      console.log("error: ", error.message)
    }
    setLoading(false)
  }

  const columns = [
    {
      Header: "ID",
      disableFilters: false,
      accessor: row => <CopyField text={row.id} />,
    },

    {
      Header: "Name",
      // accessor: "meta",
      disableFilters: true,
      filterable: true,
      accessor: ({ img_url, first_name, last_name }) => {

        const handleError = (e) => {
          e.target.src = "/images/default_user.svg"; // Replace with your placeholder image URL
          e.target.onerror = null; // Prevent infinite loop in case fallback also fails
        };

        return (
          <div className="d-flex align-items-center" >

            <img
              className="rounded-circle avatar-xs"
              src={getImageUrl(img_url)}
              onError={handleError}
            />

            <span
              style={{ marginLeft: "10px" }}
            >{`${first_name} ${last_name}`}</span>
          </div>
        )
      },
    },
    {
      Header: "Email",
      accessor: row => <CopyField text={row.email} />,
      filterable: false,
      disableFilters: true,

    },
    {
      Header: "Phone Number",
      accessor: "phone",
      filterable: false,
      disableFilters: true,

    },

    {
      Header: "Join Date",
      filterable: true,
      disableFilters: true,
      accessor: "created_at",

      Cell: cellProps => {
        return moment(cellProps.row.original.created_at).format(
          DATE_FORMATS.CREATED_AT
        )
      },
    },

  ]

  return (
    <div className="page-content">
      <Container fluid>
        {loading ? (
          <DefaultLoading />
        ) : (
          <Row>
            <Col lg="12">
              <div className="mb-4 h4 card-title">Users</div>
              <Card>
                <CardBody>
                  <TableContainer
                    columns={columns}
                    data={usersData}
                    isSortBy={false}
                    isGlobalFilter={true}
                    isAddUserList={false}
                    isFilter={false}
                    customPageSize={10}
                    className="custom-header-css"
                    currentItems={currentItems}
                    setCurrentItems={setCurrentItems}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        )}
      </Container>
    </div>
  )
}

export default withRouter(UnverifiedUsers)
