import React from "react"
import PropTypes from "prop-types"
import { withRouter } from "react-router-dom"
import { Card, CardBody } from "reactstrap"

import TableContainer from "../../components/Common/TableContainer"
import { Box } from "@material-ui/core"

const PromosLists = ({
    columns,
    data,
    selection,
    currentItems, setCurrentItems,
    header,
}) => {
    return (
        <React.Fragment>
            <Card>
                <CardBody>
                    <div className="d-flex justify-content-between  mb-2">
                        <div className=" h4 card-title">Promo Packages</div>

                    </div>
                    {data.length ? (
                        <TableContainer
                            columns={columns}
                            data={data}
                            isGlobalFilter={false}
                            isAddOptions={false}
                            customPageSize={6}
                            selection={selection}
                            header={header}
                            currentItems={currentItems}
                            setCurrentItems={setCurrentItems}
                        />
                    ) : (
                        <Box
                            alignItems="center"
                            justifyContent="center"
                            style={{ minHeight: "50vh", whiteSpace: "nowrap" }}
                            sx={{
                                p: 5,
                                fontSize: "14px",
                                display: "flex",
                                alignItems: "center",
                                color: "red",
                            }}
                        >
                            No Promos Found.
                        </Box>
                    )}
                </CardBody>
            </Card>
        </React.Fragment>
    )
}

PromosLists.propTypes = {
    orders: PropTypes.array,
    onGetOrders: PropTypes.func,
}

export default withRouter(PromosLists)

