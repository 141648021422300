import React, { useState } from "react"
import { Link } from "react-router-dom"
import * as moment from "moment"
import { Badge } from "reactstrap"
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"
import { STATUS, TABLES } from "pages/Utility/constants"
import { supabase } from "pages/Utility/supabase"
import { fundsTransfer } from "pages/Utility/stripe_functions"

const CheckBox = cell => {
  return cell.value ? cell.value : ""
}

const OrderId = cell => {
  return (
    <Link to="#" className="text-body fw-bold">
      {cell?.row.original.id ? cell?.row.original.id : ""}
    </Link>
  )
}

const Date = cell => {
  const date = moment(cell.row.original.created_at).format("LL")
  return date ? date : ""
}

const UserId = cell => {
  return cell.cell.row.original.user_id ? cell.cell.row.original.user_id : ""
}
const StripeId = cell => {
  return cell.cell.row.original.stripe_account
    ? cell.cell.row.original.stripe_account
    : ""
}

const Status = cell => {
  return (
    <Badge
      style={{ textTransform: "capitalize" }}
      className={
        "font-size-11 badge-soft-" +
        (cell?.row?.original.approved === "inReview" ? "warning" : "warning")
      }
    >
      {"in Review"}
    </Badge>
  )
}

const Amount = cell => {
  return cell.cell.row.original.amount
    ? "$" + (+cell.cell.row.original?.amount).toFixed(2)
    : ""
}

const Action = cell => {
  const [dropdownOpen, setDropdownOpen] = useState(false)

  const toggle = () => setDropdownOpen(prevState => !prevState)
  const {
    stripe_acount: stripeAcc,
    amount: payAmount,
    id,
    transfer_id,
    user_id,
  } = cell.row?.original

  const handlePayout = async () => {
    //checking connect account approval
    cell.setLoad(true)
    const { data: profile, error } = await supabase
      .from(TABLES.USER_PROFILE)
      .select("connect_account_approval")
      .eq("id", user_id)
      .single()

    if (error) {
      cell.setLoad(false)
      return alert(error.message)
    }

    if (!profile?.connect_account_approval) {
      cell.setLoad(false)
      return cell?.showPopUp && cell.showPopUp(cell.row?.original)
    }

    // checking connect account approval

    const response = await fundsTransfer({ stripeAcc, payAmount, transfer_id })

    console.log("response: ", response)

    if (response?.payoutID && response?.transferID) {
      const { data: update, error } = await supabase
        .from(TABLES.REFERRAL_PAYOUTS)
        .update({
          transfer_id: response?.transferID,
          payout_id: response?.payoutID,
          status: STATUS.REFERRAL_PAYOUT_COMPLETED,
        })
        .eq("id", id)

      if (error) {
        cell.setLoad(false)
        return console.log(error, "Supabase not updating")
      }

      cell?.refresh && cell.refresh()

      console.log(update, "updatedSupabase")
    } else {
      const { data, err } = await supabase
        .from(TABLES.REFERRAL_PAYOUTS)
        .update({ transfer_id: response?.transferID })
        .eq("id", id)

      if (err) {
        console.log(err)
        cell.setLoad(false)
        alert(err)
      }
      cell?.refresh && cell.refresh()
      cell.setLoad(false)
    }
  }
  const handleReject = async () => {
    try {
      const { data, err } = await supabase
        .from(TABLES.REFERRAL_PAYOUTS)
        .update({ status: STATUS.REFERRAL_PAYOUT_REJECTED })
        .eq("id", id)
      if (err) {
        throw err
      }
      console.log(data, "rejected")
      cell?.refresh && cell.refresh()
    } catch (error) {
      console.log(error)
    }
  }
  const handleStatus = value => {
    if (value === "Approve") {
      handlePayout()
    }
    if (value === "Reject") {
      handleReject()
    }
  }

  return (
    <div className="d-flex marginButton" style={{ overflow: "visible" }}>
      <Dropdown
        isOpen={dropdownOpen}
        toggle={toggle}
        direction={"down"}
      >
        <DropdownToggle caret>Select</DropdownToggle>
        <DropdownMenu className="dropdownMenu absolute">
          <DropdownItem onClick={e => handleStatus(e.target.innerText)}>
            Approve
          </DropdownItem>
          <DropdownItem onClick={e => handleStatus(e.target.innerText)}>
            Reject
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </div>
  )
}
export { CheckBox, OrderId, Date, UserId, StripeId, Status, Amount, Action }
