import { createClient } from "@supabase/supabase-js"
import { PAGE_SIZE } from "pages/Utility/constants"

const supabaseUrl = process.env.REACT_APP_SUPABASE_URL
const supabaseKey = process.env.REACT_APP_SUPABASE_ANON_KEY

const supabase = createClient(supabaseUrl, supabaseKey)

async function fetchPaginatedData({
    table,
    select = '*',
    page = 1,
    pageSize = PAGE_SIZE,
    orderBy = 'id',
    ascending = true,
    filters = [],
    orConditions = null
}) {
    const start = (page - 1) * pageSize
    const end = start + pageSize - 1

    let query = supabase
        .from(table)
        .select(select, { count: 'exact' })
        .range(start, end)
        .order(orderBy, { ascending })

    // Apply standard filters
    filters.forEach(filter => {
        query = query.filter(filter.column, filter.operator, filter.value)
    })

    // Apply OR conditions if provided
    if (orConditions) {
        query = query.or(orConditions)
    }

    const { data, count, error } = await query

    if (error) {
        console.error('Error fetching paginated data:', error)
        throw error
    }

    return {
        data,
        pagination: {
            currentPage: page,
            pageSize,
            totalCount: count || 0,
            totalPages: Math.ceil((count || 0) / pageSize),
            canNextPage: page < Math.ceil((count || 0) / pageSize),
            canPreviousPage: page !== 1
        }
    }
}

export default fetchPaginatedData
