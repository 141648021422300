import { STATUS } from "pages/Utility/constants"
import React, { memo } from "react"

const ClientBillingDetail = ({ invoiceDetail, refundHandler, refundedData, loading, cancelledData }) => {
  const Heading = ({ text, customClass }) => {
    return (
      <nav aria-label="breadcrumb">
        <ol className={`breadcrumb   ${customClass}`}>
          <li
            style={{ fontWeight: "600", color: "#495057" }}
            className="breadcrumb-item  active"
            aria-current="page"
          >
            {text}
          </li>
        </ol>
      </nav>
    )
  }

  const DISABLED_STATES = [STATUS.ORDER_REFUNDED, STATUS.ORDER_APPROVED]
  return (
    <div>
      <div className="container px-2">
        <div className="row mx-0 pb-3">
          <button
            disabled={DISABLED_STATES.includes(+invoiceDetail?.orderStatus) || loading}
            // onClick={e => setRefundModel(true)}
            onClick={refundHandler}
            className="btn btn-primary w-md me-2"
          >
            Refund
          </button>
        </div>
      </div>

      <div className={` ${"mx-2"} pb-2 bg-white rounded`}>
        <Heading text="Hangar Owner Details" customClass="m-0 " />

        <div className="d-flex gap-2 px-3 py-2">

          <img style={{ height: "12px", }} className="mt-1" src="/images/profile.png" alt="" />


          {invoiceDetail?.hangar_owner?.meta?.first_name +
            " " +
            invoiceDetail?.hangar_owner?.meta?.last_name}

        </div>
        <div className="d-flex gap-2 px-3 py-2">

          <img style={{ height: "10px" }} className="mt-1" src="/images/msg.png" alt="" />

          <div className="text-truncate" style={{ maxWidth: "calc(100% - 30px)" }}>
            {invoiceDetail?.hangar_owner?.email}
          </div>
        </div>
        <div className="d-flex gap-2 px-3 py-2">

          <img style={{ height: "12px" }} className="mt-1" src="/images/plane.png" alt="" />

          {invoiceDetail?.hangarData?.near_airport?.ICAO + " - " + invoiceDetail?.hangarData?.near_airport?.name}
        </div>
        <div className="d-flex gap-2 px-3 py-2">

          <img style={{ height: "12px" }} className="mt-1" src="/images/loca.png" alt="" />

          {invoiceDetail?.hangarData?.address}
        </div>
      </div>
      {refundedData?.reason && <div className={` mx-2 pb-2 mt-3 bg-white rounded`}>
        <Heading text="Admin Refund Reason" customClass="m-0" />


        <div className="d-flex gap-2  px-3">

          <div>{refundedData?.reason}</div>
        </div>
      </div>}
      {cancelledData?.reason && <div className={` mx-2 pb-2 mt-3 bg-white rounded`}>
        <Heading text="Customer Cancellation Reason" customClass="m-0" />


        <div className="d-flex gap-2  px-3">

          <div>{cancelledData?.reason}</div>
        </div>
      </div>}
      {/* {subscriptionData && (
        <div className={`mx-2 pb-2 mt-3 bg-white rounded`}>
          <Heading text="Subscription Details" customClass="m-0" />
          {subscriptionData.map((subscription, index) => (
            <div key={index} className="d-flex flex-column px-3">
              <div className="d-flex">
                <div><strong>Created At:</strong> {moment(subscription.created_at).format('LL')}</div>
                <div><strong>Start Date:</strong> {moment(subscription.start_date).format('LL')}</div>
                <div><strong>End Date:</strong> {moment(subscription.end_date).format('LL')}</div>
                <div><strong>Last Amount:</strong> ${subscription.last_amount}</div>
              </div>
            </div>
          ))}
        </div>
      )} */}

    </div>
  )
}

export default memo(ClientBillingDetail)
