import moment from "moment"
// import { error } from 'toastr';
import { supabase } from "./supabase"
import { STATUS, TABLES } from "./constants"
import { toast } from "react-toastify"

const KEY = process.env.REACT_APP_STRIPE_SECRET_KEY
const stripe = require("stripe")(KEY)

export const fundsTransfer = async props => {
  const { stripeAcc, amount, transfer_id } = props
  try {
    //if money transfer from an admin account to host account already transfer next flow in else case
    if (!transfer_id) {

      //if payout is attempting first time
      const transfer = await stripe.transfers.create({
        amount,
        currency: "usd",
        destination: stripeAcc,
      })

      if (transfer?.id) {
        const payout = await stripe.payouts.create(
          {
            amount,
            currency: "usd",
          },
          {
            stripeAccount: stripeAcc,
          }
        )

        if (payout?.id) {
          return { transferID: transfer.id, payoutID: payout.id }
        } else {
          return { transferID: transfer.id }
        }
      }
    } else {
      //For if transfer is completed but payout pending
      const payout = await stripe.payouts.create(
        {
          amount,
          currency: "usd",
        },
        {
          stripeAccount: stripeAcc,
        }
      )


      if (payout?.id) {
        return { transferID: transfer_id, payoutID: payout.id }
      }
    }
  } catch (error) {
    toast.error(error.message)
    // console.log(error, "Error11")
  }
}

export const setTransactionStatusToRefund = async ({ id, refundId, refundAmount, reason, paymentStatus }) => {
  try {
    const { data: inserted, error: insertedError } = await supabase.from(TABLES.REFUND)
      .insert({
        transaction_id: id,
        refund_id: refundId,
        refund_amount: refundAmount,
        reason: reason
      })
      .select()
    if (insertedError) throw insertedError

    const { data, error } = await supabase
      .from(TABLES.TRANSACTIONS)
      .update({ orderStatus: STATUS.ORDER_REFUNDED, paymentStatus })
      .eq("id", id)
      .select()
    if (error) throw error

    return inserted
  } catch (error) {
    return error
  }
}

export const refundPayment = async props => {
  const {
    id,
    payment_intent,
    created_at,
    serviceFee,
    total_Amount,
    stripeFee,
  } = props.invoiceDetail
  const diff = moment().diff(moment(created_at), "days")
  let refundAmount = 0

  if (diff <= 1) {
    refundAmount = (+total_Amount - +stripeFee) * 100
  } else {
    refundAmount = (+total_Amount - +serviceFee - +stripeFee) * 100
  }

  try {
    const intentData = await stripe.paymentIntents.retrieve(
      payment_intent
    );

    if (intentData.status !== 'succeeded') {
      const cancelPaymentIntentMethod = await stripe.paymentIntents.cancel(payment_intent);
      const resp = await setTransactionStatusToRefund({ id, refundId: payment_intent, refundAmount: total_Amount, reason: props.reasonTxt, paymentStatus: "refunded" });
      return { success: true, resp }
    } else {
      const refund = await stripe.refunds.create({
        payment_intent: payment_intent,
        amount: parseInt(refundAmount),
      })
      if (refund?.status === "succeeded") {
        const resp = await setTransactionStatusToRefund({ id, refundAmount: (refund.amount / 100), refundId: refund.id, reason: props.reasonTxt, paymentStatus: "partial_refunded" });
        if (resp?.length) return { success: true, resp }
      } else {
        throw error
      }
    }

  } catch (error) {
    console.log('Error while updating or inserting refund row', error)
    return error
  }
}


export const cancelPaymentIntentMethod = async (payment_intent) => {
  try {

    const response = await stripe.paymentIntents.cancel(payment_intent);
    return response

  } catch (error) {
    if (error?.message.includes("it has a status of canceled"))
      return { already_cancelled: true, refunded: true }

    throw error
  }
}

export const createStripeProductAndPrice = async ({ name, amount, interval = "month", tax_code }) => {
  try {
    const product_object = {
      name: `Promotion Package: ${name}`,
    }
    if (tax_code) product_object.tax_code = tax_code;

    const product = await stripe.products.create(product_object);

    const price = await stripe.prices.create({
      product: product.id,
      unit_amount: (amount * 100),
      currency: 'usd',
      recurring: { interval },
      tax_behavior: "inclusive"
    })

    await stripe.products.update(product.id, {
      default_price: price.id
    });

    if (product && price) {
      return { success: true, data: { product, price } };
    }

  } catch (error) {
    return { success: false, data: null, message: error?.message || error };
  }
}

export const updateStripeProductPrice = async ({ productId, amount, interval = "month" }) => {
  try {

    const price = await stripe.prices.create({
      product: productId,
      unit_amount: (amount * 100),
      currency: 'usd',
      recurring: { interval },
    })

    await stripe.products.update(productId, {
      default_price: price.id
    });

    if (price) {
      return { success: true, data: price };
    }

  } catch (error) {
    return { success: false, data: null, message: error?.message || error };
  }
}
