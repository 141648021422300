import React from "react"
import PropTypes from "prop-types"
import { withRouter } from "react-router-dom"
import { Card, CardBody } from "reactstrap"

import TableContainer from "../../components/Common/TableContainer"
import RequestModel from "./requestModel"
import { Box } from "@material-ui/core"

const Requests = ({
  columns,
  hangars,
  modelPopData,
  closeModal,
  selection,
  setLoading,
  getHangarsData,
  selectedRowsUserIDs,
  setAlert,
  currentItems, setCurrentItems,
  setTabIndex,
  tabIndex,
}) => {
  return (
    <React.Fragment>
      <RequestModel
        modelData={modelPopData}
        isOpen={!!modelPopData}
        toggle={closeModal}
      />
      <Card>
        <CardBody>
          <div className="mb-4 h4 card-title">Hangar Request</div>
          {hangars.length ? (
            <TableContainer
              columns={columns}
              data={hangars}
              isGlobalFilter={false}
              isAddOptions={false}
              customPageSize={6}
              selection={selection}
              setLoading={setLoading}
              getHangarsData={getHangarsData}
              selectedRowsUserIDs={selectedRowsUserIDs}
              currentItems={currentItems}
              setCurrentItems={setCurrentItems}
              setAlert={setAlert}
            />
          ) : (
            <Box
              alignItems="center"
              justifyContent="center"
              style={{ minHeight: "50vh", whiteSpace: "nowrap" }}
              sx={{
                p: 5,
                fontSize: "14px",
                display: "flex",
                alignItems: "center",
                color: "red",
              }}
            >
              No Hangars Requests Found.
            </Box>
          )}
        </CardBody>
      </Card>
    </React.Fragment>
  )
}

Requests.propTypes = {
  orders: PropTypes.array,
  onGetOrders: PropTypes.func,
}

export default withRouter(Requests)
