import React from "react"
import { Button, Col, Container, Row } from "reactstrap"
import Requests from "./requests"
import { Date, Status } from "./LatestRequestsCol"
import { useEffect, useState, useMemo } from "react"
import { supabase } from "pages/Utility/supabase"
import { BULK_BUTTONS, STATUS, TABLES } from "pages/Utility/constants"
import DefaultLoading from "common/loading"
import Alerts from "common/Alerts/Alert"
import { useHistory } from "react-router-dom"
import HorizontalMenu from "common/HorizontalMenu"
import { toast } from "react-toastify"
import { getImageUrl } from "pages/Utility/functions"
import { AVATAR } from "common/svg"

const MENUS = [
  { label: "New hangars", value: STATUS.HANGAR_INREVIEW },
  { label: "Update hangars", value: STATUS.HANGAR_UPDATE },
  { label: "Rejected hangars", value: STATUS.HANGAR_REJECTED },
]

const DEFAULT_ALERT_VALUES = {
  heading: "",
  message: "",
  type: "",
}

const HangarRequests = () => {
  const history = useHistory()
  const [loading, setLoading] = useState(true)
  const [hangars, setHangars] = useState([])
  const [modelPopData, setModelPopData] = useState(null)
  const [selectedRows, setSelectedRows] = useState([])
  const [tabIndex, setTabIndex] = useState(MENUS[0].value)
  const [allData, setAllData] = useState([])
  const [alert, setAlert] = useState(DEFAULT_ALERT_VALUES)
  const [countData, setCountData] = useState({ [STATUS.HANGAR_INREVIEW]: 0, [STATUS.HANGAR_UPDATE]: 0, [STATUS.HANGAR_REJECTED]: 0 });
  const [currentItems, setCurrentItems] = useState([]);

  document.title = "Requests | HangarDirect"

  useEffect(() => {
    getHangarsData()
  }, [])

  useEffect(() => {
    setHangars(allData.filter(i => i.status == tabIndex))
  }, [tabIndex])



  const COLUMNS = useMemo(
    () => [
      // {
      //   Header: "#",
      //   filterable: false,
      //   disableFilters: true,
      //   accessor: ({ id }) => {
      //     return (
      //       <input
      //         onChange={handleCheckChange(id)}
      //         type="checkbox"
      //         checked={selectedRows.includes(id)}
      //         className="form-check-input"
      //       />
      //     )
      //   },
      // },
      {
        Header: "Id",
        accessor: "id",
        filterable: false,
        disableFilters: true,
      },
      {
        Header: "Title",
        accessor: "title",
        disableFilters: true,
        filterable: false,
      },

      {
        Header: "Address",
        accessor: "address",
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "User",
        accessor: ({ profile }) => {
          return <div>
            {profile?.meta?.img_url ? <img className="rounded-circle" style={{ width: "30px", height: "30px" }} src={getImageUrl(profile?.meta?.img_url)} /> : <AVATAR width={32} height={32} custom={"rounded-circle"} />}
            {" " + profile?.meta?.first_name + " " + profile?.meta?.last_name}
          </div>
        },
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "Status",
        accessor: "status",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <Status {...cellProps} tabIndex={tabIndex} />
        },
      },
      {
        Header: "Near airport",
        accessor: "near_airport",
        disableFilters: true,

      },
      {
        Header: "Created date",
        accessor: "created_at",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <Date {...cellProps} />
        },
      },

      {
        Header: "View Details",
        disableFilters: true,
        accessor: row => {
          return (
            <Button
              type="button"
              color="primary"
              className="btn-sm btn-rounded"
              onClick={() => {
                history.push("/hangar-request-details/" + row.id)
              }}
            >
              View Details
            </Button>
          )
        },
      },

    ],
    [selectedRows, hangars]
  )
  const getHangarsData = async () => {
    try {
      setLoading(true)
      let { data, error } = await supabase
        .from(TABLES.HANGARS_TEMP)
        .select("*, hangar_id(*), profile(*)")
        .order("id", { ascending: true })

      if (error) {
        throw error
      }

      data = data.reduce((acc, item) => {
        if (item.data) {
          acc.push({
            ...item,
            ...item.data,
            ...item.hangar_id,
            status: item.status,
            id: item.id,
            created_at: item?.created_at,
            profile: item.profile
          })
        }
        return acc
      }, [])


      const counts = data.reduce((acc, item) => {
        if (item.status == STATUS.HANGAR_INREVIEW)
          acc[STATUS.HANGAR_INREVIEW] += 1
        else if (item.status == STATUS.HANGAR_UPDATE)
          acc[STATUS.HANGAR_UPDATE] += 1
        else if (item.status == STATUS.HANGAR_REJECTED)
          acc[STATUS.HANGAR_REJECTED] += 1
        return acc
      }, { [STATUS.HANGAR_INREVIEW]: 0, [STATUS.HANGAR_UPDATE]: 0, [STATUS.HANGAR_REJECTED]: 0 })


      setCountData(counts)
      setHangars(data.filter(i => i.status == STATUS.HANGAR_INREVIEW))
      setAllData(data)
      setCurrentItems(data);
    } catch (error) {
      toast.error(error.message)
      console.log("error: ", error.message)
    }
    setLoading(false)
  }



  const handleBulk = async (status) => {

    try {


      // const status =status===BULK_BUTTONS.DELETE ?
      if (status === BULK_BUTTONS.DELETE) {
        setLoading(true)
        const { data, error } = await supabase.from(TABLES.HANGARS_TEMP).delete()
          .in("id", selectedRows)
        if (error) throw error
        else {
          setSelectedRows([])
          getHangarsData()
        }
      }

    } catch (error) {
      toast.error(error.message)
      setLoading(false)
    }


  }

  return (
    <div className="page-content">
      <Container fluid>
        {alert.type && (
          <Alerts
            type={alert.type}
            heading={alert.heading}
            message={alert.message}
            clear={() => setAlert(DEFAULT_ALERT_VALUES)}
          />
        )}
        {
          loading ?
            <DefaultLoading />
            :
            <Row>
              <Col lg="12">
                <Requests
                  columns={COLUMNS}
                  hangars={hangars}
                  header={
                    <HorizontalMenu
                      menu={MENUS.map(i => ({ ...i, label: `${i.label} (${countData[i.value]})` }))}
                      active={tabIndex}
                      onClick={(val) => setTabIndex(val)}
                      getHangarsData={getHangarsData}
                    />
                  }
                  bulkButtons={[BULK_BUTTONS.DELETE,]}
                  handleBulk={handleBulk}
                  modelPopData={modelPopData}
                  closeModal={() => setModelPopData(null)}
                  selection={selectedRows}
                  setLoading={setLoading}
                  setTabIndex={setTabIndex}
                  tabIndex={tabIndex}
                  getHangarsData={getHangarsData}
                  setAlert={setAlert}
                  className="!mb-0"
                  loading={loading}
                  currentItems={hangars}
                  setCurrentItems={setCurrentItems}
                />
              </Col>
            </Row>
        }
      </Container>
    </div>
  )
}

export default HangarRequests

