import React, { Fragment, useCallback, useState } from "react"
import PropTypes from "prop-types"
import {
    useTable,
    useSortBy,
    useFilters,
    useExpanded,
    usePagination,
} from "react-table"
import { Table, Row, Col, Button, Input, CardBody } from "reactstrap"
import { Filter, DefaultColumnFilter } from "./filters"
import { BULK_BUTTONS, PAGE_SIZE } from "pages/Utility/constants"
import { Box } from "@material-ui/core"
import debounce from 'lodash.debounce';


const CustomGlobalFilter = ({ handleFilter, filterText, count }) => {
    return (
        <React.Fragment>
            <Col>
                <div className="search-box my-xxl-0 w-100 d-inline-block">
                    <div style={{ borderRadius: "10px" }} className="position-relative">
                        <label htmlFor="search-bar-0" className="search-label">
                            <span id="search-bar-0-label" className="sr-only">
                                Search this table
                            </span>
                            <input
                                onChange={(e) => handleFilter(e.target.value)}
                                id="search-bar-0"
                                type="text"
                                className="form-control"
                                style={{ borderRadius: "5px", width: "300px" }}
                                placeholder={`Search from ${count} records...`}
                                value={filterText || ""}
                            />
                        </label>
                    </div>
                </div>
            </Col>
        </React.Fragment>
    )
}

const TableContainerPagination = ({
    columns,
    data,
    isGlobalFilter,
    className,
    selection,
    onRowClick,
    rowClick,
    isSelectDropdown = true,
    isFilter = true,
    header,
    isSortBy = true,
    isPage = true,
    handleFirstPage,
    handleBack,
    handleNext,
    handleLastPage,
    bulkButtons = [],
    isShowPagination = true,
    numSize,
    rowClass,
    pagination,
    refetchData,
    ...props
}) => {
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        prepareRow,
        setPageSize,
        state: { pageSize },
    } = useTable(
        {
            columns,
            data,
            defaultColumn: { Filter: DefaultColumnFilter },
            initialState: {
                pageIndex: 0,
                pageSize: numSize ? numSize : PAGE_SIZE,
                sortBy: isSortBy
                    ? [
                        {
                            desc: true,
                        },
                    ]
                    : [],
            },
        },
        useFilters,
        useSortBy,
        useExpanded,
        usePagination
    )

    //custom global filter
    const [filterText, setFilterText] = useState('');

    const handleFilter = (val) => {
        setFilterText(val);
        debouncedFetch(1, pageSize, val);
    }
    //custom global filter


    const generateSortingIndicator = column => {
        return column.isSorted ? (column.isSortedDesc ? " 🔼" : " 🔽") : ""
    }

    const onChangeInSelect = event => {
        refetchData({ page: 1, pageSize: Number(event.target.value), filter: filterText });
        setPageSize(Number(event.target.value));
    }

    const onChangeInInput = event => {
        const page = event.target.value ? Number(event.target.value) : 1;
        debouncedFetch(page, pageSize, filterText)
    }
    const debouncedFetch = useCallback(
        debounce((page, pageSize, filter) => {
            refetchData({ page: page, pageSize, filter });
        }, 300),
        []
    );

    const handleBulk = status => () => {
        props.handleBulk && props.handleBulk(status)
    }

    const showPagination = pagination?.totalPages > 1;

    const pageDataFetch = (page) => {
        debouncedFetch(page, pageSize, filterText)
    }

    return (
        <Fragment>
            <div className="row">
                {isSelectDropdown && (
                    <div className="row justify-content-between p-0 mb-3">
                        <div style={{ marginLeft: "12px" }} className="col-3">
                            {isPage && (
                                <select
                                    className="form-select"
                                    value={pageSize}
                                    onChange={onChangeInSelect}
                                >
                                    {[10, 20, 30, 40, 50].map(pageSize => (
                                        <option key={pageSize} value={pageSize}>
                                            Show {pageSize}
                                        </option>
                                    ))}
                                </select>
                            )}
                        </div>
                        {isGlobalFilter && <div className="col-2 p-0 ml-auto">
                            <CustomGlobalFilter count={pagination?.totalCount} filtered={data} filterText={filterText} handleFilter={handleFilter} />
                        </div>}
                        {header ? <div style={{ width: "60%" }} className="col-4 p-0">{header}</div> : null}
                    </div>
                )}

            </div>

            <div className="table-responsive react-table">
                <Table hover {...getTableProps()} className={className}>
                    <thead className="table-light table-nowrap">
                        {headerGroups.map(headerGroup => (
                            <tr key={headerGroup.getHeaderGroupProps().key} role="">
                                {headerGroup.headers.map(column => (
                                    <th key={column.id}>
                                        <div className="mb-" {...column.getSortByToggleProps()}>
                                            {column.render("Header")}
                                            {generateSortingIndicator(column)}
                                        </div>
                                        {isFilter && <Filter column={column} />}
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>

                    <tbody
                        style={{ backgroundColor: "#000", verticalAlign: "middle" }}
                        {...getTableBodyProps()}
                    >
                        {page.map(row => {
                            prepareRow(row)
                            return (
                                <Fragment key={row.getRowProps().key}>
                                    <tr
                                        className={"" + " " + rowClass}
                                        onClick={() => {
                                            if (!rowClick) return
                                            onRowClick(row)
                                        }}
                                    >
                                        {row.cells.map(cell => {
                                            return (
                                                <td
                                                    style={{
                                                        maxWidth: "200px",
                                                        // overflow: "hidden",
                                                        textOverflow: "ellipsis",
                                                        // whiteSpace: "nowrap",
                                                    }}
                                                    key={cell.getCellProps().key}
                                                    role="cell"
                                                >
                                                    {cell.render("Cell")}
                                                </td>
                                            )
                                        })}
                                    </tr>
                                </Fragment>
                            )
                        })}
                    </tbody>
                </Table>
                {page?.length === 0 && (
                    <Box
                        alignItems="center"
                        justifyContent="center"
                        style={{
                            minHeight: "50vh",
                            whiteSpace: "nowrap",
                            textAlign: "center",
                        }}
                        sx={{
                            p: 5,
                            fontSize: "14px",
                            display: "flex",
                            alignItems: "center",
                            color: "red",
                        }}
                    >
                        No records found.
                    </Box>
                )}
            </div>
            <Row className="justify-content-md-end justify-content-center align-items-center">
                {/* selected rows */}
                {!!selection?.length && (
                    <Col className="col-md-auth">
                        {bulkButtons.map(btn => (
                            <Button
                                key={btn}
                                onClick={handleBulk(btn)}
                                color={
                                    btn === BULK_BUTTONS.APPROVE
                                        ? "success"
                                        : btn === BULK_BUTTONS.REJECT
                                            ? "warning"
                                            : "danger"
                                }
                                className="me-2"
                            >
                                {btn}
                            </Button>
                        ))}
                    </Col>
                )}
                {/* pagination controls */}
                {showPagination && isShowPagination && (
                    <>
                        <Col className="col-md-auto">
                            <div className="d-flex gap-1">
                                <Button
                                    color="primary"
                                    onClick={() => pageDataFetch(1)}
                                    disabled={!pagination?.canPreviousPage}
                                >
                                    {"<<"}
                                </Button>
                                <Button
                                    color="primary"
                                    // onClick={isPage ? previousPage : handleBack}
                                    onClick={() => pageDataFetch(pagination?.currentPage - 1)}
                                    disabled={!pagination?.canPreviousPage}
                                >
                                    {"<"}
                                </Button>
                            </div>
                        </Col>

                        <Col className="col-md-auto d-none d-md-block">
                            Page{" "}
                            <strong>
                                {pagination?.currentPage} of {pagination?.totalPages}
                            </strong>
                        </Col>
                        {isPage && (
                            <Col className="col-md-auto">
                                <Input
                                    type="number"
                                    min={1}
                                    style={{ width: 70 }}
                                    max={pagination?.totalPages}
                                    defaultValue={pagination?.currentPage}
                                    onChange={onChangeInInput}
                                />
                            </Col>
                        )}

                        <Col className="col-md-auto">
                            <div className="d-flex gap-1">
                                <Button
                                    color="primary"
                                    onClick={() => pageDataFetch(pagination?.currentPage + 1)}
                                    disabled={!pagination?.canNextPage}
                                >
                                    {">"}
                                </Button>
                                <Button
                                    color="primary"
                                    onClick={() => {
                                        pageDataFetch(pagination?.totalPages)
                                    }}
                                    disabled={!pagination?.canNextPage}
                                >
                                    {">>"}
                                </Button>
                            </div>
                        </Col>
                    </>
                )}
            </Row>
        </Fragment>
    )
}

TableContainerPagination.propTypes = {
    preGlobalFilteredRows: PropTypes.any,
}

export default TableContainerPagination
