export const IMAGE_BASE_URL = `${process.env.REACT_APP_SUPABASE_URL}/storage/v1/object/public/`

export const TABLES = {
  HANGARS: "hangar",
  HANGARS_TEMP: "hangar_temp",
  AIRPORTS: "airport",
  FAVORITES: "favorite",
  TRANSACTIONS: "transaction",
  TRANSACTIONS_CANCEL: "transactions_cancel",
  AMENITIES: "amenity",
  HANGAR_AMENITIES: "hangar_amenity",
  PROPERTY_RULES: "property_rules",
  HANGAR_RULES: "hangar_rule",
  RULES: "rules",
  USER_PLANES: "plane",
  PLANE_LIST: "plane_list",
  USER_PROFILE: "profile",
  USER_AVATARS: "avatars",
  STATUS: "status",
  PAYOUTS: "payouts",
  FEEDBACK: "feedback",
  REFUND: "refund",
  REFERRAL_PAYOUTS: "referral_payouts",
  REFERRALS: "referrals",
  SUBSCRIBERS: "subscribers",
  SUBSCRIPTIONS: "subscriptions",
  HELP_DESK: "help_desk",
  HELP_DESK_TYPES: "help_desk_types",
  REVIEWS: 'reviews',
  PAYMENT_METHODS: "payment_methods",
  UNVERIFIED_USERS: "unverified_user",
  ALERT_HANGARS: "alert",
  WIDGET: "collection_widget",
  WIDGET_COLLECTION: "collection",
  COLLECTION_PRODUCT: "collection_product",
  PROMO_PACKAGES: "promotion_package",
  // FAQ: "faq",
}


export const BUCKETS = {
  HANGAR_IMAGES: "hangars",
  AVATARS: "avatars",
  ARTICLES: "help_desk"
};

export const ACCOUNT_TYPES = {
  BANK: "bank_account",
  CARD: "card",
};

export const STATUS = {
  HANGAR_INREVIEW: 1,
  HANGAR_APPROVED: 2,
  HANGAR_REJECTED: 3,
  HANGAR_DELETED: 4,
  HANGAR_UPDATE: 21,
  HANGAR_DRAFT: 5,
  ORDER_INPROCESS: 6,
  ORDER_CANCELED: 7,
  ORDER_APPROVED: 8,
  ORDER_REFUNDED: 9,
  PAYOUT_PENDING: 10,
  PAYOUT_COMPLETED: 11,
  PAYOUT_REJECTED: 12,
  REFERRAL_PAYOUT_PENDING: 13,
  REFERRAL_PAYOUT_PROCESSING: 14,
  REFERRAL_PAYOUT_COMPLETED: 15,
  REFERRAL_PAYOUT_REJECTED: 16,
  CONNECT_ACCOUNT_INREVIEW: 17,
  CONNECT_ACCOUNT_PROCESSING: 18,
  CONNECT_ACCOUNT_APPROVED: 19,
  CONNECT_ACCOUNT_REJECTED: 20,
  PLANE_INSURANCE_PENDING: 22,
  PLANE_INSURANCE_APPROVED: 23,
  PLANE_INSURANCE_REJECTED: 24,
  PLANE_INSURANCE_NOT_PROVIDED: 25,
  PAYMENT_METHOD_IN_REVIEW: 26,
  PAYMENT_METHOD_APPROVED: 27,
  PAYMENT_METHOD_REJECTED: 28,
  PAYMENT_METHOD_DELETED: 29,
  CANCEL_ORDER_IN_REVIEW: 30,
  CANCEL_ORDER_APPROVED: 31,
  CANCEL_ORDER_REJECTED: 32,
  CONNECT_ADMIN_APPROVAL_IN_REVIEW: 36,
  CONNECT_ADMIN_APPROVAL_APPROVED: 37,
  CONNECT_ADMIN_APPROVAL_REJECTED: 38,
  LEASING_TERM_NIGHTLY: 39,
  LEASING_TERM_MONTHLY: 40,
  LEASING_TERM_ALL: 40,
  LEASING_TERM_SALE: 41
}

export const BULK_BUTTONS = {
  "APPROVE": "Approve all",
  "REJECT": "Reject All",
  "DELETE": "Delete all"
}


export const DATE_FORMATS = {
  CREATED_AT: "LL"
}

export const PAGE_SIZE = 10



export const ROUTES = {
  FAQ_PAGE: "/faqs",
  FAQ_ADD: "/faqs/add",
}

